import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../service/auth.service';
import {ExporterService} from '../../../../service/exporter.service';
import {GruposService} from '../../../../service/grupos.service';
import {map} from 'rxjs/operators';

interface Grupo {
  Nombre?: string;
  Disciplina?: string;
  Dependencia?: string;
  Responsable?: string;
  Integrantes?: string;
}

@Component({
  selector: 'app-reporte-grupos',
  templateUrl: './reporte-grupos.component.html',
  styleUrls: ['./reporte-grupos.component.css']
})
export class ReporteGruposComponent implements OnInit {
  dataSourceGrupos: Grupo[] = [];
  displayedColumns: string[] = ['Nombre', 'Disciplina', 'Dependencia', 'Responsable', 'Integrantes'];

  constructor(private authService: AuthService,
              private excelService: ExporterService,
              private gruposService: GruposService) { }

  ngOnInit() {
    this.cargarGrupos();
  }

  cargarGrupos() {
    this.gruposService.getAllGroups()
      .pipe(
        map( (grupos: any) => {
            for (const grupo of grupos) {
              let usuarioTemp = '';
              if (grupo.idResponsable) {
                this.authService.getUserById(grupo.idResponsable).subscribe((usuario: any) => {
                  if (usuario) {
                    usuarioTemp = `${usuario.nombre} ${usuario.apellido}`;
                    grupo.Responsable = usuarioTemp;
                    grupo.Email_Responsable = usuario.email;
                  }
                });
              }

              let dependenciasTemp = '';
              if (grupo.dependencias) {
                for (const dep of grupo.dependencias) {
                  dependenciasTemp = dependenciasTemp.concat(`${dep}, `);
                }
                dependenciasTemp = dependenciasTemp.slice(0, -2);
              }

              let integrantesTemp = '';
              if (grupo.integrantes) {
                for (const integrante of grupo.integrantes) {
                  if (integrante.userLastName && integrante.userName) {
                    integrantesTemp = integrantesTemp.concat(`${integrante.userLastName ? integrante.userLastName : ''} ${integrante.userName ? integrante.userName : ''}, `);
                  }
                }
                integrantesTemp = integrantesTemp.slice(0, -2);
              }

              grupo.Nombre = grupo.nombre;
              grupo.Disciplina = grupo.disciplina;
              grupo.Dependencia = dependenciasTemp;
              grupo.Responsable = usuarioTemp;
              grupo.Integrantes = integrantesTemp;

              delete grupo.email;
              delete grupo.capacidad1;
              delete grupo.capacidad2;
              delete grupo.capacidad3;
              delete grupo.capacidades;
              delete grupo.convNombre1;
              delete grupo.convNombre2;
              delete grupo.convWeb1;
              delete grupo.convWeb2;
              delete grupo.convenios;
              delete grupo.dependencias;
              delete grupo.descripcion;
              delete grupo.direccion;
              delete grupo.disciplina;
              delete grupo.id;
              delete grupo.idResponsable;
              delete grupo.integrantes;
              delete grupo.nombre;
              delete grupo.responsableEmail;
              delete grupo.sitioWeb;
              delete grupo.telefono;
              delete grupo.urlPhoto;
            }

            return grupos;
          }
        )
      )
      .subscribe( grupos => {
        this.dataSourceGrupos = Object.values(grupos);
      });

  }

  generarReporte() {
    this.excelService.exportToExcel(this.dataSourceGrupos, 'detalle_grupos_db');
  }

}
