import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }



  seleccionCampTematico(termino:Event){
   
    this.router.navigate(['/vistaGrupos', termino]);
  }

  buscarGrupo(termino:string){
    this.router.navigate(['/buscarGrupos', termino]);
  }










  scriptsSlider(){
    $(document).ready(function() {
      (<any> $('.hero-slider')).slick({
          slidesToShow: 1,
          autoplay: true,
          autoplaySpeed: 5000,
          infinite: true,
          speed: 300,
          dots: true,
          arrows: true,
          fade: true,
          responsive: [{
              breakpoint: 600,
              settings: {
                  arrows: false
              }
          }]
      });
  
 

   // Item Slider
      (<any> $('.items-container')).not('.slick-initialized').slick({
    infinite: true,
    arrows: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                arrows: false
            }
        },
        {
            breakpoint: 525,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
        }
    ]
});


});

  }

}
