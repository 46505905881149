import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GruposService } from 'src/app/service/grupos.service';

@Component({
  selector: 'app-buscador-grupos',
  templateUrl: './buscador-grupos.component.html',
  styleUrls: ['./buscador-grupos.component.css']
})
export class BuscadorGruposComponent implements OnInit {
  p: number = 1;
  grupos:any[]=[];
  termino:string;

  constructor(private activatedRoute:ActivatedRoute,
    private _grupoService:GruposService
    ) {


  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params =>{
      this.termino=params['termino'];
      this.grupos=this._grupoService.buscarGrupos(params['termino']);
    })
  }


}
