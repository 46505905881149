
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UsuarioModel } from '../../app/models/usuario.model';
import { AngularFireAuth } from '@angular/fire/auth';
import {map, switchMap} from 'rxjs/operators';
import { firestore, } from 'firebase/app';
import { FormGroup } from '@angular/forms';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';
import * as firebase from 'firebase';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private url = 'https://identitytoolkit.googleapis.com/v1/';
  private apikey = 'AIzaSyAANB9g_Fl3zm8CBC6iO3nu4UB4lkafbDg';
  private usuariosCollection: AngularFirestoreCollection<UsuarioModel>;
  public user$: Observable<UsuarioModel>;

  constructor(
    private http: HttpClient,
    private afsAuth: AngularFireAuth,
    private afs: AngularFirestore
  ) {
    this.user$ = this.afsAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs.doc<UsuarioModel>(`usuarios/${user.uid}`).valueChanges();
        }
        return of(null);
      })
    );
  }

  async resetPassword(email: string): Promise<void> {
    try {
      return firebase.auth().sendPasswordResetEmail(email);
    } catch (error) {
      console.log('error :>> ', error);
    }
  }

  async sendVerificationEmail(): Promise<void> {
    return (await firebase.auth().currentUser).sendEmailVerification();
  }

  getAllUsers() {
    return this.afs.collection('usuarios').valueChanges();
  }

  registerUser(forma: FormGroup) {
    return new Promise((resolve, reject) => {
      let usuario: UsuarioModel;
      this.afsAuth.auth.createUserWithEmailAndPassword(forma.value.email, forma.value.pass1)
        .then(res => {
          resolve(res),
          usuario = {
            email: res.user.email,
            id: res.user.uid,
            enable: true
          }
          this.updateUserData(usuario);
          this.sendVerificationEmail();
        }).catch(err => reject(err));
    });
  }

  private updateUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`usuarios/${user.id}`);
    return userRef.set(user, { merge: true });
  }

  getUserData(id: string) {
    return this.afs.doc(`usuarios/${id}`);
  }

  getUserById(id: string) {
    return this.afs.doc(`usuarios/${id}`).valueChanges();
  }

  updateUserProfile(forma: FormGroup, usr: UsuarioModel) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`usuarios/${usr.id}`);
    const data: UsuarioModel = {
      id: usr.id,
      email: usr.email,
      nombre: forma.value.nombre,
      apellido: forma.value.apellido,
      gradoAcademico: forma.value.gradoAcademico,
      rol: forma.value.roles,
      telefono: forma.value.telefono,
      cuil: forma.value.cuil,
      photoUrl: forma.value.urlPhoto,
      dependencias: forma.value.dependencias,
      grupoPertenece: forma.value.grupoPertenece,
      enable: true
    };
    return userRef.set(data, { merge: true });
  }

  updateUserProfileInvestigator(forma: FormGroup, usr: UsuarioModel) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`usuarios/${usr.id}`);
    const data: UsuarioModel = {
      descripcion: forma.value.descripcion,
      linkedin: forma.value.linkedin,
      cvar: forma.value.cvar,
      researchGate: forma.value.researchgate,
      sitioPropio: forma.value.sitiopropio,
      micrositio: forma.value.micrositio,
      intereses: forma.value.intereses,
      disciplina: forma.value.disciplina,
      participacionProyecto: forma.value.participacionProyecto,
      enable: true
    };

    if (forma.value.tipoDeProyectos) {
      data.tipoDeProyectos = forma.value.tipoDeProyectos;
    } else if (forma.value.participacionProyecto == 2) {
      data.tipoDeProyectos = [];

    } else {
      data.tipoDeProyectos = [];
      data.participacionProyecto = '0';
    }

    return userRef.set(data, { merge: true });
  }

  loginEmailUser(email: string, pass: string) {
    return new Promise((resolve, reject) => {
      this.afsAuth.auth.signInWithEmailAndPassword(email, pass)
        .then(userData => resolve(userData),
          err => reject(err));
    });
  }

  logoutUser() {
    return this.afsAuth.auth.signOut();
  }

  isAuth() {
    return this.afsAuth.authState.pipe(map(auth => auth));
  }

  nuevoUsuario(usuario: UsuarioModel) {
    const authData = {
      ...usuario,
      returnSecureToken: true
    };

    return this.http.post(
      `${this.url}accounts:signUp?key=${this.apikey}`, authData
    );
  }

  deleteUserById(user: UsuarioModel) {
    this.usuariosCollection = this.afs.collection('usuarios');
    return this.usuariosCollection.doc(user.id).delete();
  }

  borrarCuenta(usr: UsuarioModel) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`usuarios/${usr.id}`);
    return userRef.update({
      nombre: firestore.FieldValue.delete(),
      gradoAcademico: firestore.FieldValue.delete(),
      rol: firestore.FieldValue.delete(),
      photoUrl: firestore.FieldValue.delete(),
      telefono: firestore.FieldValue.delete(),
      cuil: firestore.FieldValue.delete(),
      apellido: firestore.FieldValue.delete(),
      descripcion: firestore.FieldValue.delete(),
      interesesEvaluador: firestore.FieldValue.delete(),
      dependencias: firestore.FieldValue.delete(),
      cvar: firestore.FieldValue.delete(),
      linkedin: firestore.FieldValue.delete(),
      researchGate: firestore.FieldValue.delete(),
      sitioPropio: firestore.FieldValue.delete(),
      tipoDeProyecto: firestore.FieldValue.delete(),
      esMovilizado: firestore.FieldValue.delete(),
      grupoACargo: firestore.FieldValue.delete(),
      grupoPertenece: firestore.FieldValue.delete(),
      disciplina: firestore.FieldValue.delete(),
      intereses: firestore.FieldValue.delete(),
      participacionProyecto: firestore.FieldValue.delete(),
      micrositio: firestore.FieldValue.delete()
    });
  }



  verificaRol(rol) {
    return new Promise(resolve => {
      this.isAuth().subscribe(res => {
        if (!res) {
          resolve(false);
        } else {

          this.getUserById(res.uid).pipe(
            map((res2: any) => {

                if (res2 && res2.rol.includes(rol)) {
                  resolve(true);
                } else {
                  resolve(false);
                }


              }
            )
          ).subscribe();

        }
      });
    });
  }
}
