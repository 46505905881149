import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestigadorService, Investigador } from 'src/app/service/investigadores.service';
import { Observable } from 'rxjs/internal/Observable';


@Component({
  selector: 'app-buscador-investigadores',
  templateUrl: './buscador-investigadores.component.html',
  styleUrls: ['./buscador-investigadores.component.css']
})
export class BuscadorInvestigadoresComponent implements OnInit {
  p: number = 1;
  investigadores: Investigador[] = [];
  termino: string;

  constructor(private activatedRoute: ActivatedRoute,
    private _investigadorService: InvestigadorService,
    private router: Router
  ) {


  }

  ngOnInit() {

    this.activatedRoute.params.subscribe(params => {
      this.termino = params['termino'].toLowerCase();
      this._investigadorService.getInvestigadoresFirebase().subscribe(data => {
        data.forEach(element => {

            const nombre = element.nombre ? element.nombre.toLowerCase() : [];
            const disciplina = element.disciplina ? element.disciplina.toLowerCase() : [];

            if (nombre.indexOf(this.termino) >= 0 || disciplina.indexOf(this.termino) >= 0) {
              this.investigadores.push(element);
            }
        });
      });
    });
  }




  verInvestigador(idx: number) {

    this.router.navigate(['/investigador', idx]);

  }

}
