import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Observable } from 'rxjs/internal/Observable';
import { UsuarioModel } from 'src/app/models/usuario.model';
import * as $ from 'jquery';

@Component({
  selector: 'app-send-verification',
  templateUrl: './send-verification.component.html',
  styleUrls: ['./send-verification.component.css'],
  providers: [AuthService]
})
export class SendVerificationComponent implements OnInit {
  public user$: Observable<UsuarioModel> = this.authService.user$;

  constructor(private authService: AuthService) { }

  ngOnInit() {
   
  }


  onSendEmail(){
    this.authService.sendVerificationEmail();
  }



 


}
