export interface Roles {
    admin?: boolean;
    investigador?: boolean;
    docente?: boolean;
    movilizado?: boolean;
    personalApoyo?: boolean;
    alumnoPreGrado?: boolean;
    alumnoDeGrado?: boolean;
    alumnoDePosGrado?: boolean;
    alumnoEgresado?: boolean;
  }

export class UsuarioModel{
    id?: string;
    email?: string;
    password?: string;
    nombre?: string;
    rol?: string[];
    photoUrl?: string;
    telefono?: Phone;
    cuil?: string;
    apellido?: string;
    descripcion?: string;
    interesesEvaluador?: string[];
    dependencias?: string[];
    cvar?: string;
    linkedin?: string;
    researchGate?: string;
    sitioPropio?: string;
    tipoDeProyecto?: string;
    tipoDeProyectos?: string[];
    esMovilizado?: boolean;
    grupoACargo?: string;
    grupoPertenece?: string;
    disciplina?: string;
    intereses?: string[];
    participacionProyecto?: string;
    micrositio?: string;
    gradoAcademico?: string;
    enable?: boolean;
}

interface Phone {
  number?: string;
internationalNumber?: string;
nationalNumber?: string;
countryCode?: string;
dialCode?: string;
id?: string;
}
