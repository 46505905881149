import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase/app';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public email: string = '';
  public password: string = '';

  constructor( public afAuth: AngularFireAuth, private router: Router, private authService: AuthService ) { }

  ngOnInit() {
  }

  onLogin(): void {
    this.authService.loginEmailUser(this.email, this.password).
    then((res:any) =>{
      if (res && res.user.emailVerified) {
        this.router.navigate(['/home']);
      } else if (res){
        this.router.navigate(['/verificacion']);
      } else {
        this.router.navigate(['/registro']);
      }

      
    }).catch(err => console.log('err', err.message));
  }
  onLogout(){
    this.authService.logoutUser();
  }

}
