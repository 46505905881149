export class EquipoModel {
    id ?: string;
    codigo ?: string; // codigo es el numero de serie
    marca ?: string;
    modelo ?: string;
    equipamiento ?: string;
    responsableId ?: string;
    dependencia ?: string[];
    contacto ?: string;
    servicios ?: string[];
    convenios ?: Convenio[];
    aplicaciones ?: string;
    reservaEstado ?: boolean;
    photoUrl?: string;
    descripcion?: string;
    telefono?: Phone;
    email?: string;
}


export class Convenio {
    nombre?: string;
    web?: string;
    
  }
  interface Phone {
    number?: string;
  internationalNumber?: string;
  nationalNumber?: string;
  countryCode?: string;
  dialCode?: string;
  id?: string;
  }