import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable()
export class InvestigadorService {




          private usuariosCollection: AngularFirestoreCollection<UsuarioModel>;
          private usuarios: Observable<UsuarioModel[]>;
          private userDoc: AngularFirestoreDocument<UsuarioModel>;
          private user: Observable<UsuarioModel>;
          public selectedUser: UsuarioModel = {
            id: null
          };

    constructor(private afs: AngularFirestore) {

    }


    // .where('rol', 'array-contains', 'Docente-Investigador')
    getInvestigadoresFirebase(){
        this.usuariosCollection = this.afs.collection<UsuarioModel>('usuarios', ref => ref.where('rol', 'array-contains-any', ['Investigador', 'Docente-Investigador']).where('enable', '==', true).orderBy('apellido', 'asc'));
        return this.usuarios = this.usuariosCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data() as Investigador;

          data.nombre = `${action.payload.doc.data().nombre} ${action.payload.doc.data().apellido}`,
          data.bio = action.payload.doc.data().descripcion,
          data.img = action.payload.doc.data().photoUrl,
          data.disciplina = action.payload.doc.data().disciplina;
          return data;
        });
      }));
    }





    getInvestigadorFirebase(idx: string) {
        this.userDoc = this.afs.doc<UsuarioModel>(`usuarios/${idx}`);
        return this.user = this.userDoc.snapshotChanges().pipe(map(action => {
          if (action.payload.exists == false) {
            // console.log('return null ');
            return null;
          } else {
            const data = action.payload.data() as Investigador;
            data.idx = action.payload.data().id,
            data.nombre = `${action.payload.data().nombre} ${action.payload.data().apellido}`,
          data.bio = action.payload.data().descripcion,
          data.img = action.payload.data().photoUrl,
          data.disciplina = action.payload.data().disciplina;
            data.rol = action.payload.data().rol;
            return data;
          }
        }));
      }

    // buscarInvestigadores(termino: string): Investigador[] {
    //   let investigadoresArr: Investigador[]=[];
    //   termino = termino.toLowerCase();
    //   // tslint:disable-next-line: prefer-for-of
    //   for (let i = 0; i < this.investigadores.length; i ++ ) {


    //     let investigador =this.getInvestigadoresFirebase()[i];

    //     const nombre= investigador.nombre.toLowerCase();
    //     const disciplina= investigador.disciplina.toLowerCase();
    //     if (nombre.indexOf(termino)>=0 || disciplina.indexOf(termino)>=0) {
    //         investigador.idx=i.toString();
    //         investigadoresArr.push(investigador);
    //     }
    //   }
    //   return investigadoresArr;
    // }

    getInvestigadoresPorDisciplina(termino: string) {
        this.usuariosCollection = this.afs.collection('usuarios', ref => ref.where('disciplina', '==', termino));
        return this.usuarios = this.usuariosCollection.snapshotChanges()
          .pipe(map(changes => {
            return changes.map(action => {
              const data = action.payload.doc.data() as Investigador;
              data.idx = action.payload.doc.id;
              data.nombre = `${action.payload.doc.data().nombre} ${action.payload.doc.data().apellido}`,
          data.bio = action.payload.doc.data().descripcion,
          data.img = action.payload.doc.data().photoUrl,
          data.disciplina = action.payload.doc.data().disciplina;
              return data;
            });
          }));
      }


}

export interface Investigador {

    nombre: string;
    rol ?: string[];
    bio: string;
    img: string;
    disciplina: string;
    idx ?: string;
    cvar ?: string;
    linkedin ?: string;
    sitioPropio ?: string;
    researchGate ?: string;
    email ?: string;

}
