import { Component, OnInit } from "@angular/core";
import { GruposService } from "src/app/service/grupos.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ExporterService } from '../../../service/exporter.service';


export interface ReportsByGroupTable {
  dependency: number;
  groups: string;
}


@Component({
  selector: "app-reports-by-group",
  templateUrl: "./reports-by-group.component.html",
  styleUrls: ["./reports-by-group.component.css"],
})
export class ReportsByGroupComponent implements OnInit {
  unsubscribe$ = new Subject();
  displayedColumns: string[] = ["dependency", "groups"];
  dataSource = [];

  constructor(private gruposService: GruposService, private excelService: ExporterService) { }

  ngOnInit() {
    this.tableData();
  }

  tableData() {
    this.gruposService
      .getAllGroups()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((values) => {
        if (values) {
          const depSet = new Set();
          values.forEach(group => {
            if (group.dependencias) {
              group.dependencias.forEach(dep => {
                depSet.add(dep);
              });
            }
          });

          const report = [];

          Array.from(depSet).forEach((dependency: string, index: number) => {

            report.push({ dependency: dependency, groups: 0 })

            values.forEach(group => {
              if (group.dependencias.find(dep => dep === dependency)) {
                report[index].groups++;
              }
            })
          });

          this.dataSource = report;
        }
      });
  }
  exportAsXLSX(): void {
    this.excelService.exportToExcel(this.dataSource, 'reporte_por_grupos')

  }
}
