import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { DatePipe } from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class ReservaService {

  constructor( ) { }


  form: FormGroup = new FormGroup({
    
    nombre: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
    telefono: new FormControl('', [Validators.required, Validators.minLength(8)]),
    empresa: new FormControl('', Validators.required),
    fecha: new FormControl('', Validators.required),
    mensaje: new FormControl('', Validators.required),
    mailEncargado: new FormControl(''),
    nombreEquipo: new FormControl(''),
    nroInventario: new FormControl(''),
    dependencia: new FormControl('')
  });

  initializeFormGroup() {
    this.form.setValue({
      nombre: '',
      email: '',
      telefono: '',
      empresa: '',
      fecha: '',
      mensaje: '',
      mailEncargado: '',
      nombreEquipo: '',
      nroInventario: '',
      dependencia: ''
    });
  }


  get nombreNoValido() {
   
      return this.form.get('nombre').invalid && this.form.get('nombre').touched;
    

  }
  get emailNoValido() {
    return this.form.get('email').invalid && this.form.get('email').touched;
}

get telefonoNoValido() {
  return this.form.get('telefono').invalid && this.form.get('telefono').touched;
}

get empresaNoValido() {
  return this.form.get('empresa').invalid && this.form.get('empresa').touched;
}
get fechaNoValido() {
  return this.form.get('fecha').invalid && this.form.get('fecha').touched;
}
get mensajeNoValido() {
  return this.form.get('mensaje').invalid && this.form.get('mensaje').touched;
}



 

  populateForm(employee) {
    this.form.setValue(employee);
  }
}

