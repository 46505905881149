import { Component, OnInit } from '@angular/core';
import {RolesService} from '../../service/roles.service';
import {Route, Router, RouterLink} from '@angular/router';
import Swal from 'sweetalert2';
import {RolModel} from '../../models/rol.model';
import {AuthService} from '../../service/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  rol: RolModel = {
    id: '',
    nombre: ''
  };

  roles: RolModel[];

  displayedColumns: string[] = ['nombre', 'acciones'];
  dataSource = [];



  constructor(private rolesService: RolesService,
              private router: Router,
              private spinnerService: NgxSpinnerService,
              private authService: AuthService ) { }

  ngOnInit() {
    this.authService.verificaRol('superadmin').then((verificado: any) => {
      if (verificado) {
        this.cargarRoles();
      } else {
        this.router.navigateByUrl('/home');
      }
    });
  }

  cargarRoles() {
    this.spinnerService.show();
    this.rolesService.getAllRoles().subscribe(
      (resp: RolModel[]) => {
        if (resp === null) { return this.roles = []; }
        this.roles = resp;
        this.spinnerService.hide();
      },
      (err) => {
        console.log('Se produjo un error:', err);
      }
    );
  }


  onEdit(rol) {
    this.router.navigate(['/rol', rol.id]);
  }

  onDelete(rol) {
    return;
    this.rol = rol;
    Swal.fire({
      title: '¿Esta seguro que desea eliminar este Rol?',
      text: 'El cambio es irreversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
    }).then((result) => {
      if (result.value) {
        this.rolesService
          .deleteRolById(this.rol)
          .then(() => this.router.navigate(['/roles']));
        Swal.fire('Eliminado', 'El Rol ha sido eliminado.', 'success');
      }
    });
  }


}
