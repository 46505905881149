import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { LinkModel } from "../models/link.model";
import {UsuarioModel} from "../models/usuario.model";

@Injectable({
  providedIn: "root",
})
export class LinksService {
  private docLink: AngularFirestoreDocument<LinkModel>;
  private linkCollection: AngularFirestoreCollection<LinkModel>;

  constructor(private afs: AngularFirestore) {}

  getAllLinks() {
    return this.afs
      .collection('linksDeInteres', ref => ref.orderBy('orden'))
      .valueChanges({ idField: 'id' });
  }

  getLinkById(linkId: string) {
    return this.afs.collection("linksDeInteres").doc(linkId).valueChanges();
  }

  updateLink(link: LinkModel, linkId): Promise<any> {
    return this.afs.collection("linksDeInteres").doc(linkId).update(link);
  }

  addLink(link: LinkModel): Promise<any> {
    return this.afs.collection("linksDeInteres").add(link);
  }

  deleteLinkById(link: LinkModel) {
    this.linkCollection = this.afs.collection<LinkModel>("linksDeInteres");
    return this.linkCollection.doc(link.id).delete();
  }


}
