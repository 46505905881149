import { Component, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.css']
})
export class ReportCardComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }


  @Input() title: string;
  @Input() imageSrc: string; 

}
