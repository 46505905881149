import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { UsuarioModel } from '../../../models/usuario.model';
import { ValidadoresService } from '../../../service/validadores.service';
import { AuthService } from '../../../service/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

  usuario: UsuarioModel;
  forma: FormGroup;
  dominios = [
    'uncu.edu.ar',
    'itu.uncu.edu.ar',
    'iusp.uncu.edu.ar',
    'ib.edu.ar',
    'fad.uncu.edu.ar',
    'fca.uncu.edu.ar',
    'fcai.uncu.edu.ar',
    'fce.uncu.edu.ar',
    'fcm.uncu.edu.ar',
    'fcp.uncu.edu.ar',
    'derecho.uncu.edu.ar',
    'fed.uncu.edu.ar',
    'ffyl.uncu.edu.ar',
    'ingenieria.uncuyo.edu.ar',
    'fodonto.uncu.edu.ar',
    'fcen.uncu.edu.ar',
    'mendoza-conicet.gob.ar',
    'conicet.gob.ar',
    'conicet.gov.ar',
    'cab.cnea.gov.ar',
    'gm.fad.uncu.edu.ar',
    // delete gmail
    // 'gmail.com'
  ];
  dominiosSort;

  constructor(private fb: FormBuilder, private validadores: ValidadoresService, private auth: AuthService, private router: Router) {
    this.crearFormulario();
  }

  ngOnInit() {
    this.dominiosSort = this.dominios.sort();
    this.usuario = new UsuarioModel();
  }

  get correoNoValido() {
    return this.forma.get('email').invalid;
  }

  get pass1NoValido() {
    return this.forma.get('pass1').invalid && this.forma.get('pass1').touched;
  }
  get pass2NoValido() {
    const pass1 = this.forma.get('pass1').value;
    const pass2 = this.forma.get('pass2').value;
    return (pass1 === pass2) ? false : true;
  }

  crearFormulario() {
    this.forma = this.fb.group({
      nombre: ['', Validators.required],
      selectedDomain: ['', Validators.required],

      email: ['', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]],

      pass1: ['', Validators.required],
      pass2: ['', Validators.required],
      enable: [true]

    }, {
      validators: this.validadores.passwordsIguales('pass1', 'pass2')
    });
  }

  guardar() {
    this.forma.get('email').setValue(`${this.forma.get('nombre').value}@${this.forma.get('selectedDomain').value}`);

    if (this.forma.invalid) {
      return Object.values(this.forma.controls).forEach(control => {
        control.markAsTouched();
      });
    } else {
      this.auth.registerUser(this.forma)
        .then((res) => {
          this.router.navigate(['/verificacion']);
        }).catch(err => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: err.message
          });
        });
    }

  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.forma.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }
}
