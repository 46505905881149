import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GruposService, Grupo } from 'src/app/service/grupos.service';
import { Observable } from 'rxjs/internal/Observable';
import { GrupoModel } from 'src/app/models/grupo.model';

@Component({
  selector: 'app-vista-grupos',
  templateUrl: './vista-grupos.component.html',
  styleUrls: ['./vista-grupos.component.css']
})
export class VistaGruposComponent implements OnInit {

  p: number = 1;
  groups: Grupo[];
  grupos: Observable<Grupo[]>;
  termino:string;

  constructor(private activatedRoute:ActivatedRoute,
    private _grupoService:GruposService
    ) { 


  }
    
  ngOnInit() {
    this.activatedRoute.params.subscribe(params =>{
      this.termino=params['termino'];
      this.termino=this.termino.charAt(0).toUpperCase() + this.termino.slice(1).toLowerCase();
      this.grupos = this._grupoService.getGrupoFirebaseDisciplina(params['termino']);
      this._grupoService.getGrupoFirebaseDisciplina(params['termino']).subscribe((data) => {
        this.groups = data;
      });
    })
  }


}
