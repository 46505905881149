import { Component, OnInit } from '@angular/core';
import {RolesService} from '../../service/roles.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {RolModel} from '../../models/rol.model';
import {AuthService} from '../../service/auth.service';

@Component({
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styleUrls: ['./rol.component.css']
})
export class RolComponent implements OnInit {
  rolForm: FormGroup;
  isEdit = false;
  rolId: string;
  rol: RolModel;



  constructor(
    private route: ActivatedRoute,
    private rolesService: RolesService,
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.authService.verificaRol('superadmin').then((verificado: any) => {
      if (verificado) {
        this.crearFormulario();
        this.cargarFormulario();
      } else {
        this.router.navigateByUrl('/home');
      }
    });
  }

  cargarFormulario() {
    this.route.params
      .pipe(
        take(1)
      )
      .subscribe((params) => {
        if (params.id) {
          this.isEdit = true;
          this.rolId = params.id;
          this.rolesService.getRolById(params.id)
            .pipe(
              take(1)
            )
            .subscribe((rol: RolModel) => {
              this.rolForm.reset(rol);
            });
        }
      });
  }


  onSaveRol() {
    this.rol = this.rolForm.value;
    this.rolesService
      .addRol(this.rol)
      .then(() => {
        this.router.navigate(['/roles']);
      })
      .catch((error) => {
        console.log('ERROR->', error);
      });
  }

  onEditRol() {
    this.rol = this.rolForm.value;
    this.rolesService
      .updateRol(this.rol, this.rolId)
      .then(() => {
        this.router.navigate(['/roles']);
      })
      .catch((error) => {
        console.log('ERROR->', error);
      });
  }


  crearFormulario() {
    this.rolForm = this.fb.group({
      nombre: ['', [Validators.required, Validators.minLength(4), Validators.maxLength(30)]]
    });
  }


}
