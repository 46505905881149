import { Component, OnInit } from "@angular/core";
import {AuthService} from '../../../service/auth.service';
import {Router} from "@angular/router";

@Component({
  selector: "app-Reports",
  templateUrl: "./Reports.component.html",
  styleUrls: ["./Reports.component.css"],
})
export class ReportsComponent implements OnInit {
  constructor(private authService: AuthService,
              private router: Router) {}

  ngOnInit() {
    this.authService.verificaRol('superadmin').then((verificado: any) => {
      if (!verificado) {
        this.router.navigateByUrl('/home');
      }
    });
  }

}
