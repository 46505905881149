import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-ourinvestigators',
  templateUrl: './ourinvestigators.component.html',
  styleUrls: ['./ourinvestigators.component.css']
})
export class OurinvestigatorsComponent implements OnInit {
  
  @Input() investigador:any={};
  @Input() index:string;
 
  @Output() investigadorSeleccionado: EventEmitter<number>;

  constructor(private router:Router) { 
    this.investigadorSeleccionado= new EventEmitter();
  }

  ngOnInit() {
  }


  verInvestigador(){
      this.router.navigate(['/investigador', this.investigador.id]);
    
    //this.investigadorSeleccionado.emit(this.index);
  }


  
}
