import { OnInit, Input } from '@angular/core';
import { Component, NgZone } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4maps from '@amcharts/amcharts4/maps';

import am4geodata_worldLow from '@amcharts/amcharts4-geodata/worldLow';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import am4geodata_lang_ES from '@amcharts/amcharts4-geodata/lang/ES'; 
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ViajeService } from 'src/app/service/viajes.service';
import { ViajeModel } from 'src/app/models/viaje.model';
import Swal from 'sweetalert2';
import { PaisesService } from 'src/app/service/paises.service';

/* Chart code */
// Themes begin
// am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);

// Themes end



@Component({
  selector: 'app-interactive-map',
  templateUrl: './interactive-map.component.html',
  styleUrls: ['./interactive-map.component.css'],
  providers:[PaisesService]
})
export class InteractiveMapComponent implements OnInit{
  @Input() viajes:any[];
  
  viaje: ViajeModel = new ViajeModel();

  constructor(private zone: NgZone, private viajeService: ViajeService, private paisesService: PaisesService) {
    


    
  }
  ngOnInit(): void {
  
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngAfterViewInit() {

  this.viajeService.getviajesFirebase().subscribe(data =>{
    this.viajes =data;
   
  }
  
  )

    let viajes = [];
    viajes= this.viajes;
   





    this.zone.runOutsideAngular(() => {
      

       // Create map instance
      const chart = am4core.create('chartdiv', am4maps.MapChart);

      // Set map definition
      chart.geodata = am4geodata_worldLow;
      chart.geodataNames = am4geodata_lang_ES;


      // Set projection
      chart.projection = new am4maps.projections.Orthographic();
      chart.panBehavior = 'rotateLongLat';
      // chart.padding(20,20,20,20);

      chart.deltaLatitude = -20;
      chart.padding(20,20,20,20);

      // limits vertical rotation
      chart.adapter.add('deltaLatitude', function(delatLatitude){
        return am4core.math.fitToRange(delatLatitude, -90, 90);
      });

      // sacar el aumento cuando paso el mouse
      chart.seriesContainer.draggable = false;
      chart.seriesContainer.resizable = false;
      chart.chartContainer.wheelable = false;



      
      
      
      
      // Create map polygon series
      const polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());

      // Set min/max fill color for each area
      polygonSeries.heatRules.push({
        property: 'fill',
        target: polygonSeries.mapPolygons.template,
        // min: chart.colors.getIndex(60).brighten(1),
        // max: chart.colors.getIndex(60).brighten(-0.3)
        min: am4core.color('#f78f1b').brighten(0.9),
        max: am4core.color('#003f73').brighten(-0.3)

      });

      polygonSeries.data = [{
        'id': 'AR',
       'color': am4core.color('#f00'),
        'fill': am4core.color('#f00')
     }];
      // polygonSeries.getPolygonById("FR").fill = am4core.color("#f00");
      // map.dragMap = false;
      // Make map load polygon data (state shapes and names) from GeoJSON
      polygonSeries.useGeodata = true;

      // Set heatmap values for each state
      // map.dragMap = false;
      // polygonSeries.data.push(viajes)
      // viajes;

      polygonSeries.events.on('beforedatavalidated', function(ev) {
        var source = ev.target.data;


        // if (source.maybe) {
        //   ev.target.data = source.maybe.here.values;
        // }
      });
           

      this.viajes.forEach(element => {
    
        const cant = this.viajes.filter(x => x.codigoPais === element.codigoPais).length;
        polygonSeries.data.push({id: element.codigoPais, value: cant })
      
      });
      
      
      
    
     

// Set up heat legend
      const heatLegend = chart.createChild(am4maps.HeatLegend);
      heatLegend.series = polygonSeries;
      heatLegend.align = 'right';
      heatLegend.valign = 'bottom';
      heatLegend.width = am4core.percent(20);
      heatLegend.marginRight = am4core.percent(4);
      heatLegend.minValue = 0;
      heatLegend.maxValue = 40000000;


// Hide Antarctica
//polygonSeries.exclude = ["AQ"];

// Set up custom heat map legend labels using axis ranges
      const minRange = heatLegend.valueAxis.axisRanges.create();
      
      minRange.value = heatLegend.minValue;
      minRange.label.text = '- vinculados';
      const maxRange = heatLegend.valueAxis.axisRanges.create();
      maxRange.value = heatLegend.maxValue;
      maxRange.label.text = '+ vinculados';
      
// Blank out internal heat legend value axis labels
      heatLegend.valueAxis.renderer.labels.template.adapter.add('text', function(labelText) {
        return '';
      });

// Configure series tooltip
      const polygonTemplate = polygonSeries.mapPolygons.template;
      polygonTemplate.tooltipText = '{name}: Internacionalizados {value}';
      polygonTemplate.nonScalingStroke = true;
      polygonTemplate.strokeWidth = 0.5;

      // para cambiar el color de las lineas limitrofes
      polygonTemplate.stroke = am4core.color('#454a58');

      const graticuleSeries = chart.series.push(new am4maps.GraticuleSeries());
      graticuleSeries.mapLines.template.line.stroke = am4core.color('black');
      graticuleSeries.mapLines.template.line.strokeOpacity = 0.08;
      graticuleSeries.fitExtent = false;


      chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 0.1;
      chart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color('black');


      // para que APAREZCA VERDE Y CELESTE
      polygonTemplate.fill = am4core.color('#d7e7ce');
      polygonTemplate.stroke = am4core.color('#aaa');
      polygonTemplate.strokeWidth = 1;

      chart.backgroundSeries.mapPolygons.template.polygon.fill = am4core.color("#aadaff");
      chart.backgroundSeries.mapPolygons.template.polygon.fillOpacity = 1;
      chart.backgroundSeries.toBack();

      ////////////////////

      // PARA QUE MUESTRE EL PIN DE ARG

      // Create image series
      var imageSeries = chart.series.push(new am4maps.MapImageSeries());

      // Create image
      var imageSeriesTemplate = imageSeries.mapImages.template;
      var marker = imageSeriesTemplate.createChild(am4core.Image);
      marker.width = 28;
      marker.height = 28;
      marker.nonScaling = true;
      marker.tooltipText = '{title}';
      marker.horizontalCenter = "middle";
      marker.verticalCenter = 'middle';
      marker.propertyFields.href = 'flag';

      // Set property fields
      imageSeriesTemplate.propertyFields.latitude = 'latitude';
      imageSeriesTemplate.propertyFields.longitude = 'longitude';

      // Add data for the three cities
      imageSeries.data = [{
        "latitude": -32.8908400,
        "longitude": -68.8271700,
        'title': "Universida Nacional de Cuyo - Mendoza",
        'flag': 'assets/images/icons/Pin-Argentina.png'
      }];

/////////////

      // Create hover state and set alternative fill color
      let hs = polygonTemplate.states.create('hover');
      // hs.properties.fill = chart.colors.getIndex(0).brighten(-0.5);
      // para cambiar el color de cuando el mouse pasa por el pais
      hs.properties.fill = am4core.color('rgba(253, 164, 50, 0.68)');

      let animation;
      setTimeout(function(){
        animation = chart.animate({property:'deltaLongitude', to:100000}, 20000000);
      }, 3000)

      chart.seriesContainer.events.on('down', function(){
      if(animation){
        animation.stop();
      }
      });





//////////




      polygonTemplate.events.on('hit', function(ev) {
  if (viajes) {
    ev.target.series.chart.zoomToMapObject(ev.target/*, this.COUNTRY_ZOOM*/);
    const data: any = ev.target.dataItem.dataContext;
    let info = document.getElementById('info');

    const id = data.id;
    const viaje:any = viajes.filter((item) => item.codigoPais == id);
    info.innerHTML = '<h3 style="margin: 18px;">' + data.name + '</h3>';
    // ' (' + data.id + ')
    
    if (viaje) {
    viaje.forEach(element => {
      info.innerHTML += '<span> <i class="fa fa-map"></i>&emsp;'+element.userName + ' &nbsp;&nbsp;&nbsp;&nbsp;&emsp; &emsp;   '+ element.dependenciaDestino+'  &nbsp;&nbsp;&nbsp;&nbsp;&emsp; &emsp;  '+ element.anioDeViaje+ '  &nbsp;&nbsp;&nbsp;&nbsp;&emsp; &emsp; Testimonio: <a data-fancybox style="color:#b73333;" href="'+element.videoUrl+'"> '+ '<i class="fab fa-youtube"></i>'+'<a><span> <br> ';
    });
    
    
  } else {
    info.innerHTML += '<i>No description provided.</i>';
  }
  } else {
    Swal.fire('The Internet?', 'That thing is still around?', 'question');
  }
  
});





// Create hover state and set alternative fill color
// let hs = polygonTemplate.states.create("hover");




      // Zoom control
      chart.zoomControl = new am4maps.ZoomControl();
      chart.zoomControl.valign = 'middle';

      let homeButton = new am4core.Button();
      homeButton.events.on('hit', function(){
        chart.goHome();
      });

      homeButton.icon = new am4core.Sprite();
      homeButton.padding(7, 5, 7, 5);
      homeButton.width = 30;
      homeButton.icon.path = "M16,8 L14,8 L14,16 L10,16 L10,10 L6,10 L6,16 L2,16 L2,8 L0,8 L8,0 L16,8 Z M16,8";
      homeButton.marginBottom = 10;
      homeButton.parent = chart.zoomControl;
      homeButton.insertBefore(chart.zoomControl.plusButton);









    });
  }


  selectPais(codigo):void{
    this.viajeService.getViajesPorPais(codigo).subscribe(data => this.viajes=data)
  }

}
