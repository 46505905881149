import { Component, OnInit } from '@angular/core';
import {map} from 'rxjs/operators';
import {TechOfferService} from '../../../../service/tech-offer.service';
import {AuthService} from '../../../../service/auth.service';
import {ExporterService} from '../../../../service/exporter.service';

interface OfertasTecno {
  Nombre?: string;
  Responsable?: string;
  Area?: string;
  Tipo_Oferta?: string;
  Dependencia?: string;
  Grupo?: string;
  Campo_Aplicacion?: string;
}

@Component({
  selector: 'app-reporte-ofertas-tecno',
  templateUrl: './reporte-ofertas-tecno.component.html',
  styleUrls: ['./reporte-ofertas-tecno.component.css']
})
export class ReporteOfertasTecnoComponent implements OnInit {
  dataSourceOfertasTecno: OfertasTecno[] = [];
  displayedColumns: string[] = ['Nombre', 'Responsable', 'Area', 'Tipo_Oferta', 'Dependencia', 'Grupo', 'Campo_Aplicacion'];

  constructor(private authService: AuthService,
              private ofertasTecnoService: TechOfferService,
              private excelService: ExporterService) { }

  ngOnInit() {
    this.cargarOfertasTecno();
  }


  cargarOfertasTecno() {
    this.ofertasTecnoService.getAllTechOffers()
      .pipe(
        map( (ofertas: any) => {
            for (const oferta of ofertas) {
              if (oferta.idResponsable) {
                let responsable = '';
                this.authService.getUserById(oferta.idResponsable).subscribe((usuario: any) => {
                  responsable = `${usuario.nombre} ${usuario.apellido}`;
                  oferta.Responsable = responsable;
                });
              }


              if (oferta.groupData) {
                let dependencias = '';
                for (const dependencia of oferta.groupData.dependencies) {
                  dependencias = dependencias.concat(`${dependencia}, `);
                }
                dependencias = dependencias.slice(0, -2);
                oferta.Dependencia = dependencias;
                oferta.Grupo = oferta.groupData.groupName;
              }

              oferta.Nombre = oferta.techOfferName;
              oferta.Area = oferta.generalArea;
              oferta.Tipo_Oferta = oferta.offerType;
              oferta.Campo_Aplicacion = oferta.applicationField_1;

              delete oferta.applicationField_1Id;
              delete oferta.applicationField_2;
              delete oferta.applicationField_2Id;
              delete oferta.applicationField_3;
              delete oferta.applicationField_3Id;
              delete oferta.applicationSubfield_1;
              delete oferta.applicationSubfield_2;
              delete oferta.applicationSubfield_3;
              delete oferta.createDate;
              delete oferta.generalAreaId;
              delete oferta.groupId;
              delete oferta.id;
              delete oferta.idResponsable;
              delete oferta.keyWords;
              delete oferta.newKeyWords;
              delete oferta.offerTypeId;
              delete oferta.projectBounding;
              delete oferta.projectBoundingType;
              delete oferta.referrerId;
              delete oferta.secondaryOfferType;
              delete oferta.secondaryOfferTypeId;
              delete oferta.secondarySubOfferType;
              delete oferta.secondarySubOfferTypeId;
              delete oferta.subGeneralArea;
              delete oferta.subGeneralAreaId;
              delete oferta.subOfferType;
              delete oferta.subOfferTypeId;
              delete oferta.techOfferDescription;
              delete oferta.transferPotential;
              delete oferta.transferPotentialOption;
              delete oferta.applicationField_1;
              delete oferta.generalArea;
              delete oferta.offerType;
              delete oferta.techOfferName;
              delete oferta.specificArea;
              delete oferta.updateDate;
              delete oferta.groupData;

            }

            return ofertas;
          }
        )
      )
      .subscribe( ofertasTecno => {
          this.dataSourceOfertasTecno = Object.values(ofertasTecno);
      });
  }

  generarReporte() {
    this.excelService.exportToExcel(this.dataSourceOfertasTecno, 'detalle_ofertas_tecno_db');
  }

}
