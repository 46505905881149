import { Injectable } from '@angular/core';
import { AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';
import {RolModel} from '../models/rol.model';


@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private rolCollection: AngularFirestoreCollection<RolModel>;


  constructor(private afs: AngularFirestore) { }


  getAllRoles() {
    return this.afs
      .collection('roles')
      .valueChanges({ idField: 'id' });
  }

  getRolById(rolId: string) {
    return this.afs.collection('roles').doc(rolId).valueChanges();
  }

  updateRol(rol: RolModel, rolId): Promise<any> {
    return this.afs.collection('roles').doc(rolId).update(rol);
  }

  addRol(rol: RolModel): Promise<any> {
    return this.afs.collection('roles').add(rol);
  }

  deleteRolById(rol: RolModel) {
    this.rolCollection = this.afs.collection<RolModel>('roles');
    return this.rolCollection.doc(rol.id).delete();
  }




}
