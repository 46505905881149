import { UsuarioModel } from 'src/app/models/usuario.model';
export class GrupoModel{
    id?: string;
    nombre?: string;
    disciplina?: string;
    dependencias?: string[];
    idResponsable?: string;
    email?: string;
    direccion?: string;
    telefono?: number;
    photoUrl?: string;
    descripcion?: string;
    capacidades?: string[];
    convenios?: Convenio[];
    integrantesId?: string[];
    // unidadAcademica ?: string;
    integrantes ?: integrante[];
    urlPhoto?: string;
    capacidad1 ?: string;
    capacidad2 ?: string;
    capacidad3 ?: string;
    convNombre1?: string;
    convNombre2?: string;
    convWeb1?: string;
    convWeb2?: string;

    
    }

    
    // tslint:disable-next-line:class-name
export interface integrante {
        user?: string;
        rol?: string;
        
      }

export class Convenio {
        nombre?: string;
        web?: string;
        
      }


