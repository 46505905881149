import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [AuthService]
})
export class ForgotPasswordComponent implements OnInit {

  userEmail = new FormControl('');

  constructor( private authService: AuthService, private router: Router ) { }

  ngOnInit() {
  }


  async onReset(){
    
    try {

      const email = this.userEmail.value;
      await this.authService.resetPassword(email);
      this.router.navigate(['/login']);
      Swal.fire(
        'Por favor revisa tu bandeja de entrada',
        'Solicitud enivada con éxito.',
        'success'
      );
      
    } catch (error) {
      console.log('error :>> ', error);
    }
   
  }

}
