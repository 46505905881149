import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from "@angular/forms";


@Injectable({
  providedIn: 'root'
})

export class ContactoService {

    constructor( ) { }
  
  
    form: FormGroup = new FormGroup({
      
      nombre: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]),
      telefono: new FormControl('', [Validators.required, Validators.minLength(8)]),
      mensaje: new FormControl('', Validators.required)

    });
  
    initializeFormGroup() {
      this.form.setValue({
        nombre: '',
        email: '',
        telefono: '',
        mensaje: ''
      });
    }
  
  
  get nombreNoValido() {

    return this.form.get('nombre').invalid && this.form.get('nombre').touched;

  }
    get emailNoValido() {
      return this.form.get('email').invalid && this.form.get('email').touched;
  }
  
  get telefonoNoValido() {
    return this.form.get('telefono').invalid && this.form.get('telefono').touched;
  }

  get mensajeNoValido() {
    return this.form.get('mensaje').invalid && this.form.get('mensaje').touched;
  }

    populateForm(employee) {
      this.form.setValue(employee);
    }
  }
