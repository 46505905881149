import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShearingService {

  public isUserDeleted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  constructor() { 
    
  }
}
