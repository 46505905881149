import { Component, OnInit } from '@angular/core';
import {EquipamientoService} from '../../../../service/equipamiento.service';
import {ExporterService} from '../../../../service/exporter.service';
import {map} from 'rxjs/operators';
import {AuthService} from '../../../../service/auth.service';

interface Equipo {
  Nombre?: string;
  Responsable?: string;
  Marca?: string;
  Modelo?: string;
  Dependencia?: string;
  Email_Responsable?: string;
  Telefono?: string;
  Servicios?: string;
}

@Component({
  selector: 'app-reporte-equipamiento',
  templateUrl: './reporte-equipamiento.component.html',
  styleUrls: ['./reporte-equipamiento.component.css']
})
export class ReporteEquipamientoComponent implements OnInit {
  dataSourceEquipos: Equipo[] = [];
  displayedColumns: string[] = ['Nombre', 'Marca', 'Modelo', 'Dependencia', 'Responsable', 'Email_Responsable', 'Telefono', 'Servicios'];

  constructor(private excelService: ExporterService,
              private equipamientoService: EquipamientoService,
              private authService: AuthService) { }

  ngOnInit() {
    this.cargarEquipamiento();
  }



  cargarEquipamiento() {
    this.equipamientoService.getAllEquipos()
      .pipe(
        map( (equipos: any) => {
          if (equipos) {
            for (const equipo of equipos) {

              let usuarioTemp = '';
              if (equipo.idResponsable) {
                this.authService.getUserById(equipo.idResponsable).subscribe((usuario: any) => {
                  if (usuario) {
                    usuarioTemp = `${usuario.nombre} ${usuario.apellido}`;
                    equipo.Responsable = usuarioTemp;
                  }
                });
              }

              let serviciosTemp = '';
              if (equipo.servicios) {
                for (const serv of equipo.servicios) {
                  serviciosTemp = serviciosTemp.concat(`${serv}, `);
                }
                serviciosTemp = serviciosTemp.slice(0, -2);
              }

              let dependenciasTemp = '';
              if (equipo.dependencia) {
                for (const dep of equipo.dependencia) {
                  dependenciasTemp = dependenciasTemp.concat(`${dep}, `);
                }
                dependenciasTemp = dependenciasTemp.slice(0, -2);
              }


              equipo.Nombre = equipo.equipamiento;
              equipo.Marca = equipo.marca;
              equipo.Modelo = equipo.modelo;
              equipo.Dependencia = dependenciasTemp;
              equipo.Email_Responsable = equipo.email;
              equipo.Telefono = equipo.telefono.internationalNumber;
              equipo.Servicios = serviciosTemp;

              delete equipo.codigo;
              delete equipo.dependencia;
              delete equipo.descripcion;
              delete equipo.email;
              delete equipo.equipamiento;
              delete equipo.id;
              delete equipo.idResponsable;
              delete equipo.marca;
              delete equipo.modelo;
              delete equipo.photoUrl;
              delete equipo.servicios;
              delete equipo.telefono;
            }
          }
          return equipos;
        })
      )
      .subscribe( res => {
        this.dataSourceEquipos = Object.values(res);
    });

  }

  generarReporte() {
    this.excelService.exportToExcel(this.dataSourceEquipos, 'detalle_equipos_db');
  }

}
