import { Component, OnInit } from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {UsuarioService} from '../../../service/usuario.service';
import {UsuarioModel} from '../../../models/usuario.model';
import {RolesService} from '../../../service/roles.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {
  usuarioForm: FormGroup;
  usuario = new UsuarioModel();
  parametro = false;
  usuarioId: string;
  fileName = '';
  file;
  showImage = false;
  imageSrc: string;

  rolesList: string[];

  constructor(
    private route: ActivatedRoute,
    private usuarioService: UsuarioService,
    private fb: FormBuilder,
    private router: Router,
    private rolesServices: RolesService
  ) { }

  ngOnInit() {
    this.crearFormulario();
    this.cargarFormulario();
  }

cargarFormulario() {
  this.route.params
    .pipe(
      take(1)
    )
    .subscribe((params) => {
      if (params.id) {
        this.usuarioId = params.id;
        this.usuarioService.getUsuario(params.id)
          .pipe(
            take(1)
          )
          .subscribe((usuario: UsuarioModel) => {
            this.usuario = { ...usuario };
            this.usuarioForm.reset(this.usuario);
          });
      }
    });

  this.rolesServices.getAllRoles().subscribe( (resp: any[]) => {
    this.rolesList = resp;
  });
}

  agregarRol(roles: string[]) {
    this.clearFormArray(this.rolesItems);
    roles.forEach((element) => {
      this.rolesItems.push(this.fb.control(element));
    });
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  }

  get rolesItems() {
    return this.usuarioForm.get('rol') as FormArray;
  }

  editUsuario() {
    this.agregarRol(this.usuario.rol);
    this.usuario = { ...this.usuarioForm.value};
    this.usuarioService
      .updateUsuario(this.usuario, this.usuarioId)
      .then(() => {
        Swal.fire(
          'Actualizado!',
          'El Usuario ha sido Actualizado.',
          'success'
        );
        this.router.navigate(['/usuarios']);
      })
      .catch((error) => {
        console.log('ERROR->', error);
      });
  }



  crearFormulario() {
    this.usuarioForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      nombre: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      apellido: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(50)]],
      rol: this.fb.array([] )
    });
  }

}
