import { Injectable } from '@angular/core';


// SPANISH


@Injectable()
export class PaisesService {
 paises: Pais[] = [
    { codigo:    'AD',
      value: 'Andorra'
    },
    {    codigo: 'AE',
      value: 'Emiratos Árabes Uncodigoos (los)'
    },
    {    codigo: 'AF',
      value: 'Afganistán'
    },
    {
        codigo: 'AG',
      value: 'Antigua y Barbuda'
    },
    {
      codigo: 'AI',
      value: 'Anguila'
    },
    {
      codigo: 'AL',
      value: 'Albania'
    },
    {
      codigo: 'AM',
      value: 'Armenia'
    },
    {
      codigo: 'AO',
      value: 'Angola'
    },
    {
      codigo: 'AQ',
      value: 'Antártcodigoa'
    },
    {
      codigo: 'AR',
      value: 'Argentina'
    },
    {
      codigo: 'AS',
      value: 'Samoa Americana'
    },
    {
      codigo: 'AT',
      value: 'Austria'
    },
    {
      codigo: 'AU',
      value: 'Australia'
    },
    {
      codigo: 'AW',
      value: 'Aruba'
    },
    {
      codigo: 'AX',
      value: 'Åland, Islas'
    },
    {
      codigo: 'AZ',
      value: 'Azerbaiyán'
    },
    {
      codigo: 'BA',
      value: 'Bosnia y Herzegovina'
    },
    {
      codigo: 'BB',
      value: 'Barbados'
    },
    {
      codigo: 'BD',
      value: 'Bangladesh'
    },
    {
      codigo: 'BE',
      value: 'Bélgica'
    },
    {
      codigo: 'BF',
      value: 'Burkina Faso'
    },
    {
      codigo: 'BG',
      value: 'Bulgaria'
    },
    {
      codigo: 'BH',
      value: 'Bahréin'
    },
    {
      codigo: 'BI',
      value: 'Burundi'
    },
    {
      codigo: 'BJ',
      value: 'Benín'
    },
    {
      codigo: 'BL',
      value: 'Saint Barthélemy'
    },
    {
      codigo: 'BM',
      value: 'Bermudas'
    },
    {
      codigo: 'BN',
      value: 'Brunéi'
    },
    {
      codigo: 'BO',
      value: 'Bolivia (Estado Plurinacional de)'
    },
    {
      codigo: 'BQ',
      value: 'Bonaire, San Eustaquio y Saba'
    },
    {
      codigo: 'BR',
      value: 'Brasil'
    },
    {
      codigo: 'BS',
      value: 'Bahamas (las)'
    },
    {
      codigo: 'BT',
      value: 'Bhután'
    },
    {
      codigo: 'BV',
      value: 'Bouvet, Isla'
    },
    {
      codigo: 'BW',
      value: 'Botswana'
    },
    {
      codigo: 'BY',
      value: 'Belarús'
    },
    {
      codigo: 'BZ',
      value: 'Belice'
    },
    {
      codigo: 'CA',
      value: 'Canadá'
    },
    {
      codigo: 'CC',
      value: 'Cocos / Keeling, (las) Islas'
    },
    {
      codigo: 'CD',
      value: 'Congo (la República Democrática del)'
    },
    {
      codigo: 'CF',
      value: 'República Centroafricana (la)'
    },
    {
      codigo: 'CG',
      value: 'Congo (el)'
    },
    {
      codigo: 'CH',
      value: 'Suiza'
    },
    {
      codigo: 'CI',
      value: "Côte d'Ivoire"
    },
    {
      codigo: 'CK',
      value: 'Cook, (las) Islas'
    },
    {
      codigo: 'CL',
      value: 'Chile'
    },
    {
      codigo: 'CM',
      value: 'Camerún'
    },
    {
      codigo: 'CN',
      value: 'China'
    },
    {
      codigo: 'CO',
      value: 'Colombia'
    },
    {
      codigo: 'CR',
      value: 'Costa Rica'
    },
    {
      codigo: 'CU',
      value: 'Cuba'
    },
    {
      codigo: 'CV',
      value: 'Cabo Verde'
    },
    {
      codigo: 'CW',
      value: 'Curaçao'
    },
    {
      codigo: 'CX',
      value: 'Navcodigoad, Isla de'
    },
    {
      codigo: 'CY',
      value: 'Chipre'
    },
    {
      codigo: 'CZ',
      value: 'República Checa (la)'
    },
    {
      codigo: 'DE',
      value: 'Alemania'
    },
    {
      codigo: 'DJ',
      value: 'Djibouti'
    },
    {
      codigo: 'DK',
      value: 'Dinamarca'
    },
    {
      codigo: 'DM',
      value: 'Dominica'
    },
    {
      codigo: 'DO',
      value: 'Dominicana, (la) República'
    },
    {
      codigo: 'DZ',
      value: 'Argelia'
    },
    {
      codigo: 'EC',
      value: 'Ecuador'
    },
    {
      codigo: 'EE',
      value: 'Estonia'
    },
    {
      codigo: 'EG',
      value: 'Egipto'
    },
    {
      codigo: 'EH',
      value: 'Sahara Occcodigoental'
    },
    {
      codigo: 'ER',
      value: 'Eritrea'
    },
    {
      codigo: 'ES',
      value: 'España'
    },
    {
      codigo: 'ET',
      value: 'Etiopía'
    },
    {
      codigo: 'FI',
      value: 'Finlandia'
    },
    {
      codigo: 'FJ',
      value: 'Fiji'
    },
    {
      codigo: 'FK',
      value: 'Malvinas [Falkland], (las) Islas'
    },
    {
      codigo: 'FM',
      value: 'Micronesia (Estados Federados de)'
    },
    {
      codigo: 'FO',
      value: 'Feroe, (las) Islas'
    },
    {
      codigo: 'FR',
      value: 'Francia'
    },
    {
      codigo: 'GA',
      value: 'Gabón'
    },
    {
      codigo: 'GB',
      value: 'Reino Uncodigoo de Gran Bretaña e Irlanda del Norte (el)'
    },
    {
      codigo: 'GD',
      value: 'Granada'
    },
    {
      codigo: 'GE',
      value: 'Georgia'
    },
    {
      codigo: 'GF',
      value: 'Guayana Francesa'
    },
    {
      codigo: 'GG',
      value: 'Guernsey'
    },
    {
      codigo: 'GH',
      value: 'Ghana'
    },
    {
      codigo: 'GI',
      value: 'Gibraltar'
    },
    {
      codigo: 'GL',
      value: 'Groenlandia'
    },
    {
      codigo: 'GM',
      value: 'Gambia (la)'
    },
    {
      codigo: 'GN',
      value: 'Guinea'
    },
    {
      codigo: 'GP',
      value: 'Guadeloupe'
    },
    {
      codigo: 'GQ',
      value: 'Guinea Ecuatorial'
    },
    {
      codigo: 'GR',
      value: 'Grecia'
    },
    {
      codigo: 'GS',
      value: 'Georgia del Sur (la) y las Islas Sandwich del Sur'
    },
    {
      codigo: 'GT',
      value: 'Guatemala'
    },
    {
      codigo: 'GU',
      value: 'Guam'
    },
    {
      codigo: 'GW',
      value: 'Guinea Bissau'
    },
    {
      codigo: 'GY',
      value: 'Guyana'
    },
    {
      codigo: 'HK',
      value: 'Hong Kong'
    },
    {
      codigo: 'HM',
      value: 'Heard (Isla) e Islas McDonald'
    },
    {
      codigo: 'HN',
      value: 'Honduras'
    },
    {
      codigo: 'HR',
      value: 'Croacia'
    },
    {
      codigo: 'HT',
      value: 'Haití'
    },
    {
      codigo: 'HU',
      value: 'Hungría'
    },
    {
      codigo: 'codigo',
      value: 'Indonesia'
    },
    {
      codigo: 'IE',
      value: 'Irlanda'
    },
    {
      codigo: 'IL',
      value: 'Israel'
    },
    {
      codigo: 'IM',
      value: 'Isla de Man'
    },
    {
      codigo: 'IN',
      value: 'India'
    },
    {
      codigo: 'IO',
      value: 'Territorio Británico del Océano Índico (el)'
    },
    {
      codigo: 'IQ',
      value: 'Iraq'
    },
    {
      codigo: 'IR',
      value: 'Irán (República Islámica de)'
    },
    {
      codigo: 'IS',
      value: 'Islandia'
    },
    {
      codigo: 'IT',
      value: 'Italia'
    },
    {
      codigo: 'JE',
      value: 'Jersey'
    },
    {
      codigo: 'JM',
      value: 'Jamaica'
    },
    {
      codigo: 'JO',
      value: 'Jordania'
    },
    {
      codigo: 'JP',
      value: 'Japón'
    },
    {
      codigo: 'KE',
      value: 'Kenya'
    },
    {
      codigo: 'KG',
      value: 'Kirguistán'
    },
    {
      codigo: 'KH',
      value: 'Camboya'
    },
    {
      codigo: 'KI',
      value: 'Kiribati'
    },
    {
      codigo: 'KM',
      value: 'Comoras (las)'
    },
    {
      codigo: 'KN',
      value: 'Saint Kitts y Nevis'
    },
    {
      codigo: 'KP',
      value: 'Corea (la República Popular Democrática de)'
    },
    {
      codigo: 'KR',
      value: 'Corea (la República de)'
    },
    {
      codigo: 'KW',
      value: 'Kuwait'
    },
    {
      codigo: 'KY',
      value: 'Caimán, (las) Islas'
    },
    {
      codigo: 'KZ',
      value: 'Kazajstán'
    },
    {
      codigo: 'LA',
      value: 'Lao, (la) República Democrática Popular'
    },
    {
      codigo: 'LB',
      value: 'Líbano'
    },
    {
      codigo: 'LC',
      value: 'Santa Lucía'
    },
    {
      codigo: 'LI',
      value: 'Liechtenstein'
    },
    {
      codigo: 'LK',
      value: 'Sri Lanka'
    },
    {
      codigo: 'LR',
      value: 'Liberia'
    },
    {
      codigo: 'LS',
      value: 'Lesotho'
    },
    {
      codigo: 'LT',
      value: 'Lituania'
    },
    {
      codigo: 'LU',
      value: 'Luxemburgo'
    },
    {
      codigo: 'LV',
      value: 'Letonia'
    },
    {
      codigo: 'LY',
      value: 'Libia'
    },
    {
      codigo: 'MA',
      value: 'Marruecos'
    },
    {
      codigo: 'MC',
      value: 'Mónaco'
    },
    {
      codigo: 'MD',
      value: 'Moldova (la República de)'
    },
    {
      codigo: 'ME',
      value: 'Montenegro'
    },
    {
      codigo: 'MF',
      value: 'Saint Martin (parte francesa)'
    },
    {
      codigo: 'MG',
      value: 'Madagascar'
    },
    {
      codigo: 'MH',
      value: 'Marshall, (las) Islas'
    },
    {
      codigo: 'MK',
      value: 'Macedonia (la ex República Yugoslava de)'
    },
    {
      codigo: 'ML',
      value: 'Mali'
    },
    {
      codigo: 'MM',
      value: 'Birmania'
    },
    {
      codigo: 'MN',
      value: 'Mongolia'
    },
    {
      codigo: 'MO',
      value: 'Región Administrativa Especial de Macao de la República Popular China'
    },
    {
      codigo: 'MP',
      value: 'Marianas del Norte, (las) Islas'
    },
    {
      codigo: 'MQ',
      value: 'Martinique'
    },
    {
      codigo: 'MR',
      value: 'Mauritania'
    },
    {
      codigo: 'MS',
      value: 'Montserrat'
    },
    {
      codigo: 'MT',
      value: 'Malta'
    },
    {
      codigo: 'MU',
      value: 'Mauricio'
    },
    {
      codigo: 'MV',
      value: 'Maldivas'
    },
    {
      codigo: 'MW',
      value: 'Malawi'
    },
    {
      codigo: 'MX',
      value: 'México'
    },
    {
      codigo: 'MY',
      value: 'Malasia'
    },
    {
      codigo: 'MZ',
      value: 'Mozambique'
    },
    {
      codigo: 'NA',
      value: 'Namibia'
    },
    {
      codigo: 'NC',
      value: 'Nueva Caledonia'
    },
    {
      codigo: 'NE',
      value: 'Níger (el)'
    },
    {
      codigo: 'NF',
      value: 'Norfolk, Isla'
    },
    {
      codigo: 'NG',
      value: 'Nigeria'
    },
    {
      codigo: 'NI',
      value: 'Nicaragua'
    },
    {
      codigo: 'NL',
      value: 'Países Bajos (los)'
    },
    {
      codigo: 'NO',
      value: 'Noruega'
    },
    {
      codigo: 'NP',
      value: 'Nepal'
    },
    {
      codigo: 'NR',
      value: 'Nauru'
    },
    {
      codigo: 'NU',
      value: 'Isla Niue'
    },
    {
      codigo: 'NZ',
      value: 'Nueva Zelandia'
    },
    {
      codigo: 'OM',
      value: 'Omán'
    },
    {
      codigo: 'PA',
      value: 'Panamá'
    },
    {
      codigo: 'PE',
      value: 'Perú'
    },
    {
      codigo: 'PF',
      value: 'Polinesia Francesa'
    },
    {
      codigo: 'PG',
      value: 'Papúa Nueva Guinea'
    },
    {
      codigo: 'PH',
      value: 'Filipinas (las)'
    },
    {
      codigo: 'PK',
      value: 'Pakistán'
    },
    {
      codigo: 'PL',
      value: 'Polonia'
    },
    {
      codigo: 'PM',
      value: 'San Pedro y Miquelón'
    },
    {
      codigo: 'PN',
      value: 'Pitcairn'
    },
    {
      codigo: 'PR',
      value: 'Puerto Rico'
    },
    {
      codigo: 'PS',
      value: 'Palestina, Estado de'
    },
    {
      codigo: 'PT',
      value: 'Portugal'
    },
    {
      codigo: 'PW',
      value: 'Palau'
    },
    {
      codigo: 'PY',
      value: 'Paraguay'
    },
    {
      codigo: 'QA',
      value: 'Qatar'
    },
    {
      codigo: 'RE',
      value: 'Reunión'
    },
    {
      codigo: 'RO',
      value: 'Rumanía'
    },
    {
      codigo: 'RS',
      value: 'Serbia'
    },
    {
      codigo: 'RU',
      value: 'Rusia, (la) Federación de'
    },
    {
      codigo: 'RW',
      value: 'Rwanda'
    },
    {
      codigo: 'SA',
      value: 'Arabia Saudita'
    },
    {
      codigo: 'SB',
      value: 'Salomón, Islas'
    },
    {
      codigo: 'SC',
      value: 'Seychelles'
    },
    {
      codigo: 'SD',
      value: 'Sudán (el)'
    },
    {
      codigo: 'SE',
      value: 'Suecia'
    },
    {
      codigo: 'SG',
      value: 'Singapur'
    },
    {
      codigo: 'SH',
      value: 'Santa Helena, Ascensión y Tristán de Acuña'
    },
    {
      codigo: 'SI',
      value: 'Eslovenia'
    },
    {
      codigo: 'SJ',
      value: 'Svalbard y Jan Mayen'
    },
    {
      codigo: 'SK',
      value: 'Eslovaquia'
    },
    {
      codigo: 'SL',
      value: 'Sierra Leona'
    },
    {
      codigo: 'SM',
      value: 'San Marino'
    },
    {
      codigo: 'SN',
      value: 'Senegal'
    },
    {
      codigo: 'SO',
      value: 'Somalia'
    },
    {
      codigo: 'SR',
      value: 'Suriname'
    },
    {
      codigo: 'SS',
      value: 'Sudán del Sur'
    },
    {
      codigo: 'ST',
      value: 'Santo Tomé y Príncipe'
    },
    {
      codigo: 'SV',
      value: 'El Salvador'
    },
    {
      codigo: 'SX',
      value: 'Sint Maarten (parte neerlandesa)'
    },
    {
      codigo: 'SY',
      value: 'República Árabe Siria'
    },
    {
      codigo: 'SZ',
      value: 'Suazilandia'
    },
    {
      codigo: 'TC',
      value: 'Turcas y Caicos, (las) Islas'
    },
    {
      codigo: 'TD',
      value: 'Chad'
    },
    {
      codigo: 'TF',
      value: 'Tierras Australes Francesas (las)'
    },
    {
      codigo: 'TG',
      value: 'Togo'
    },
    {
      codigo: 'TH',
      value: 'Tailandia'
    },
    {
      codigo: 'TJ',
      value: 'Tayikistán'
    },
    {
      codigo: 'TK',
      value: 'Tokelau'
    },
    {
      codigo: 'TL',
      value: 'Timor-Leste'
    },
    {
      codigo: 'TM',
      value: 'Turkmenistán'
    },
    {
      codigo: 'TN',
      value: 'Túnez'
    },
    {
      codigo: 'TO',
      value: 'Tonga'
    },
    {
      codigo: 'TR',
      value: 'Turquía'
    },
    {
      codigo: 'TT',
      value: 'Trincodigoad y Tobago'
    },
    {
      codigo: 'TV',
      value: 'Tuvalu'
    },
    {
      codigo: 'TW',
      value: 'Taiwán (Provincia de China)'
    },
    {
      codigo: 'TZ',
      value: 'Tanzania, República Uncodigoa de'
    },
    {
      codigo: 'UA',
      value: 'Ucrania'
    },
    {
      codigo: 'UG',
      value: 'Uganda'
    },
    {
      codigo: 'UM',
      value: 'Islas Ultramarinas Menores de los Estados Uncodigoos (las)'
    },
    {
      codigo: 'US',
      value: 'Estados Uncodigoos de América'
    },
    {
      codigo: 'UY',
      value: 'Uruguay'
    },
    {
      codigo: 'UZ',
      value: 'Uzbekistán'
    },
    {
      codigo: 'VA',
      value: 'Santa Sede (la)'
    },
    {
      codigo: 'VC',
      value: 'San Vicente y las Granadinas'
    },
    {
      codigo: 'VE',
      value: 'Venezuela (República Bolivariana de)'
    },
    {
      codigo: 'VG',
      value: 'Vírgenes británicas, Islas'
    },
    {
      codigo: 'VI',
      value: 'Vírgenes de los Estados Uncodigoos, Islas'
    },
    {
      codigo: 'VN',
      value: 'Viet Nam'
    },
    {
      codigo: 'VU',
      value: 'Vanuatu'
    },
    {
      codigo: 'WF',
      value: 'Wallis y Futuna'
    },
    {
      codigo: 'WS',
      value: 'Samoa'
    },
    {
      codigo: 'YE',
      value: 'Yemen'
    },
    {
      codigo: 'YT',
      value: 'Mayotte'
    },
    {
      codigo: 'ZA',
      value: 'Sudáfrica'
    },
    {
      codigo: 'ZM',
      value: 'Zambia'
    },
    {
      codigo: 'ZW',
      value: 'Zimbabwe',
    }
];
}

export interface Pais {
    codigo ? : string,
    value ? : string;
}
