import { Component, OnInit } from '@angular/core';
import 'jquery';
import * as $ from 'jquery';
// import 'bootstrap/js/src/collapse.js';
// import 'bootstrap/js/src/dropdown.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'jquery/src/event/trigger.js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AuthService } from 'src/app/service/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { DataShearingService } from 'src/app/service/data-shearing.service';
import Swal from "sweetalert2";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  // Define a variable to use for showing/hiding the Login button
  isUserDeleted: boolean;

  data;

  private superadmin = false;

  public isLogged = false;
  usuario: UsuarioModel = new UsuarioModel();
  rolInvestigador = false;

  constructor(
    private authService: AuthService,
    private afsAuth: AngularFireAuth,
    private router: Router,
    private dataSharingService: DataShearingService
  ) {

    this.dataSharingService.isUserDeleted.subscribe(value => {
      this.isUserDeleted = value;
      if (this.isUserDeleted === true) {
        this.data = null;
        this.rolInvestigador = false;
        this.usuario = new UsuarioModel();
      } else {
        this.ngOnInit();
      }
    }).add(() => this.isUserDeleted = false);
  }

  ngOnInit() {
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.authService.isAuth().subscribe(auth => {
      if (auth) {
        this.usuario.email = auth.email;
        this.isLogged = true;
        this.authService.getUserData(auth.uid).valueChanges().subscribe((data: any) => {



          if (data) {
            if (data.enable && data.enable === false) {
              Swal.fire('Usuario Deshabilitado', 'Póngase en contacto con un Administrador', 'error');
              this.authService.logoutUser();
              this.router.navigate(['/home']);
              return;
            }

            this.data = data;
            this.usuario = this.data;



            if (this.usuario.rol) {
              // if (this.usuario.rol.length > 1 ) {
              if (this.usuario.rol.indexOf('Investigador') >= 0 && (this.usuario.rol[0].includes('Docente'))) {
                this.usuario.rol[0] = 'Docente - Investigador'
              }
            }

            if (this.usuario.rol && this.usuario.rol.length) {
              this.superadmin = this.usuario.rol.includes('superadmin');

              if (this.usuario.rol.indexOf('Investigador') >= 0 || this.usuario.rol.indexOf('Docente-Investigador') >= 0
                || (this.usuario.rol[0].includes('Docente'))) {
                this.rolInvestigador = true;
              } else {
                this.rolInvestigador = false;
              }
              if (this.usuario.rol[1]) {
                if (this.usuario.rol[1].includes('Docente')) {
                  this.rolInvestigador = true;
                }
              }
            }
          } else {
              this.superadmin = false;
              return;
          }
        });

      } else {
        this.superadmin = false;
        this.isLogged = false;
      }
    });
  }

  get isSuperadmin(): boolean {
    return this.superadmin;
  }

  onLogout() {
    this.superadmin = false;
    this.dataSharingService.isUserDeleted.next(true);
    this.authService.logoutUser();
    this.router.navigate(['/home']);
  }

}
