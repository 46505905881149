import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {UsuarioService} from '../../../service/usuario.service';
import {UsuarioModel} from '../../../models/usuario.model';
import {MatTableDataSource} from '@angular/material/table';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../service/auth.service';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  usuario = new UsuarioModel();
  usuarios: UsuarioModel[];
  dataSource = null;

  displayedColumns: string[] = ['email', 'nombre', 'apellido', 'acciones'];


  constructor(private router: Router,
              private usuarioService: UsuarioService,
              private spinnerService: NgxSpinnerService,
              private authService: AuthService ) {


  }

  ngOnInit() {
    this.authService.verificaRol('superadmin').then((verificado: any) => {
      if (verificado) {
        this.cargarUsuarios();
      } else {
        this.router.navigateByUrl('/home');
      }
    });
  }

  cargarUsuarios() {
    this.spinnerService.show();
    this.usuarioService.getUsuarios().subscribe(
      (usuarios) => {
        this.spinnerService.hide();
        this.usuarios = usuarios;
        this.dataSource = new MatTableDataSource(this.usuarios);
      },
      (err) => {
        console.log('Se produjo un error:', err);
      }
    );
  }

  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

  onEnableDisable(usuario) {
    let accion: string;
    let enable = false;
    accion = usuario.enable ? 'Deshabilitar' : 'Habilitar';
    enable = usuario.enable ? false : true;

    Swal.fire({
      title: `¿Esta seguro que desea ${accion} este Usuario?`,
      // text: 'El cambio es irreversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Si, ${accion}`,
    }).then((result) => {
      if (result.value) {

        this.usuario = new UsuarioModel();
        this.usuario = { ...usuario, enable };
        delete this.usuario.id;

        this.usuarioService
          .updateUsuario(this.usuario, usuario.id)
          .then(() => {
            this.router.navigate(['/usuarios']);
          })
          .catch((error) => {
            console.log('ERROR->', error);
          });

      }
    });
  }



  onEdit(usuario) {
    this.router.navigate(['usuario', usuario.id]);
  }


}
