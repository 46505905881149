import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { Http, HttpModule } from '@angular/http';
import "firebase/auth";
import "firebase/firestore";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SliderComponent } from './components/ElemenosDelHome/slider/slider.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { ContactoComponent } from './components/ElemenosDelHome/contacto/contacto.component';
import { OurinvestigatorsComponent } from './components/investigadores/ourinvestigators/ourinvestigators.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HomeComponent } from './components/pages/home/home.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { EquipamientoComponent } from './components/Equipos/equipamiento/equipamiento.component';
import { ServiceComponent } from './components/pages/movilizados/movilizados.component';
import { TeamComponent } from './components/grupos/team/team.component';
import { InvestigadoresComponent } from './components/investigadores/investigadores/investigadores.component';
import { RegistroComponent } from './components/User/registro/registro.component';
import { LoginComponent } from './components/User/login/login.component';
import { MaterialModule } from './material/material.module';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { InvestigadorComponent } from './components/investigadores/investigador/investigador.component';
import { InvestigadorService } from './service/investigadores.service';
import { BuscadorInvestigadoresComponent } from './components/investigadores/buscador-investigadores/buscador-investigadores.component';
import { GruposService } from './service/grupos.service';

import { VistaGruposComponent } from './components/grupos/vista-grupos/vista-grupos.component';
import { TarjetaGrupoComponent } from './components/grupos/tarjeta-grupo/tarjeta-grupo.component';
import { IntegrantesGrupoComponent } from './components/grupos/integrantes-grupo/integrantes-grupo.component';
import { BuscadorGruposComponent } from './components/grupos/buscador-grupos/buscador-grupos.component';
import { VinculosComponent } from './components/pages/vinculos/vinculos.component';
import { CatalogoComponent } from './components/pages/catalogo/catalogo.component';

// Storage
import { AngularFireStorageModule } from '@angular/fire/storage';
// Externals
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { PerfilComponent } from './components/User/perfil/perfil.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { AdministradorComponent } from './components/grupos/administrador/administrador.component';
import { CrearGrupoComponent } from './components/grupos/crear-grupo/crear-grupo.component';
import { PerfilInvestigadorComponent } from './components/User/perfil-investigador/perfil-investigador.component';
import { InteractiveMapComponent } from './components/interactive-map/interactive-map.component';

// Paginador
import { NgxPaginationModule } from 'ngx-pagination';

// Validador tel
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CargarEquipoComponent } from './components/Equipos/cargar-equipo/cargar-equipo.component';
import { VistaEquipoComponent } from './components/Equipos/vista-equipo/vista-equipo.component';
import { TablaEquiposComponent } from './components/Equipos/tabla-equipos/tabla-equipos.component';
// import { BsDropdownModule, ModalModule, PaginationModule, DatepickerModule, TabsModule } from 'ngx-bootstrap';
import { DatePipe } from '@angular/common';
import { ReservaService } from 'src/app/service/reserva.service';
import { DataShearingService } from './service/data-shearing.service';
import { SendVerificationComponent } from './components/User/send-verification/send-verification.component';
import { ForgotPasswordComponent } from './components/User/forgot-password/forgot-password.component';
import { NgxImageCompressService } from 'ngx-image-compress';
import { GestionLinksDeInteresComponent } from './components/pages/gestionLinksDeInteres/GestionLinksDeInteres.component';
import { CreateOrEditLinkComponent } from './components/pages/createOrEditLink/CreateOrEditLink.component';
import { GruposInvestigacionComponent } from './components/grupos/grupos-investigacion/grupos-investigacion.component';
import { ReportsComponent } from './components/pages/reports/Reports.component';
import { ReportCardComponent } from './components/report-card/report-card.component';
import { ReportsByGroupComponent } from './components/pages/reports-by-group/reports-by-group.component';
import { ReportsByUsersComponent } from './components/pages/reports-by-users/reports-by-users.component';
import { UsuarioComponent } from './components/User/usuario/usuario.component';
import { UsuariosComponent } from './components/User/usuarios/usuarios.component';
import { RolesComponent } from './components/roles/roles.component';
import { RolComponent } from './components/rol/rol.component';
import { ReporteUsuariosComponent } from './components/pages/reports/reporte-usuarios/reporte-usuarios.component';
import { ReporteOfertasTecnoComponent } from './components/pages/reports/reporte-ofertas-tecno/reporte-ofertas-tecno.component';
import { ReporteEquipamientoComponent } from './components/pages/reports/reporte-equipamiento/reporte-equipamiento.component';
import { ReporteViajesComponent } from './components/pages/reports/reporte-viajes/reporte-viajes.component';
import { ReporteGruposComponent } from './components/pages/reports/reporte-grupos/reporte-grupos.component';
@NgModule({
  declarations: [
    GestionLinksDeInteresComponent,
    AppComponent,
    SliderComponent,
    HeaderComponent,
    FooterComponent,
    ContactoComponent,
    OurinvestigatorsComponent,
    HomeComponent,
    ContactComponent,
    EquipamientoComponent,
    ServiceComponent,
    TeamComponent,
    InvestigadoresComponent,
    RegistroComponent,
    LoginComponent,
    InvestigadorComponent,
    BuscadorInvestigadoresComponent,
    VistaGruposComponent,
    TarjetaGrupoComponent,
    IntegrantesGrupoComponent,
    BuscadorGruposComponent,
    VinculosComponent,
    CatalogoComponent,
    PerfilComponent,
    AdministradorComponent,
    CrearGrupoComponent,
    PerfilInvestigadorComponent,
    InteractiveMapComponent,
    CargarEquipoComponent,
    VistaEquipoComponent,
    TablaEquiposComponent,
    SendVerificationComponent,
    ForgotPasswordComponent,
    CreateOrEditLinkComponent,
    RolesComponent,
    RolComponent,
    GruposInvestigacionComponent,
    ReportsComponent,
    ReportCardComponent,
    ReportsByGroupComponent,
    ReportsByUsersComponent,
    UsuarioComponent,
    UsuariosComponent,
    ReporteUsuariosComponent,
    ReporteOfertasTecnoComponent,
    ReporteEquipamientoComponent,
    ReporteViajesComponent,
    ReporteGruposComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DeviceDetectorModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpModule,

    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    NgxSpinnerModule,
    AngularFireStorageModule,
    NgxPaginationModule,
    NgxIntlTelInputModule


  ],
  providers: [AngularFireAuth, InvestigadorService, GruposService, AngularFirestore, Http, NgxSpinnerService, DatePipe, ReservaService,
    DataShearingService, NgxImageCompressService],
  bootstrap: [AppComponent]
})
export class AppModule { }
