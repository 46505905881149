import { Component, OnInit, ViewChild } from '@angular/core';
import { Investigador, InvestigadorService } from 'src/app/service/investigadores.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { NgxImageCompressService } from 'ngx-image-compress';


@Component({
  selector: 'app-aboutpage',
  templateUrl: './investigadores.component.html',
  styleUrls: ['./investigadores.component.css']
})
export class InvestigadoresComponent implements OnInit {

  investigadores: Investigador[];
  p: number = 1;

  constructor(
    private _investigadorService: InvestigadorService,
    private router: Router,
    private imageCompress: NgxImageCompressService
  ) { }

  ngOnInit() {
    this._investigadorService.getInvestigadoresFirebase().subscribe(

      // data => 

      // data.forEach(element => {
      //   let el:any = {
      //     id: element.id,
      //     codigo: element.codigo,
      //     equipamiento: element.equipamiento,
      //     dependencia: element.dependencia[0]

      //   }

      //   this.ELEMENT_DATA.push(el);
      // },

      // this.dataSource = new MatTableDataSource(this.ELEMENT_DATA)

      // )
      list => {
        let array = list.map(item => {
          // console.log('item.img :>> ', item.img);
          // this.imageCompress.compressFile(item.img, -1, 25, 50).then(
          //   result => {
          //     console.log(result);
          //     item.img = result;
          //     console.warn('Size in bytes is now:', this.imageCompress.byteCount(result));
          //   }
          // );
          return {
            ...item
          };
        });
        this.investigadores = array;

      }
    )


  }

  verInvestigador(idx: string) {

    console.log('estoy mandando el id ', idx)

    this.router.navigate(['/investigador', idx]);
  }

  buscarInvestigador(termino: string) {
    //console.log(termino);
    this.router.navigate(['/buscarInvestigador', termino]);
  }



}
