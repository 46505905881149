import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest, from } from 'rxjs';
import { TechOffer } from '../models/tech-offer.model';

@Injectable({
  providedIn: 'root'
})
export class TechOfferService {
  private lastDoc;
  private firstDoc;

  constructor(
    private afs: AngularFirestore,
    private http: HttpClient
  ) { }

  getKeyWords() {
    return this.afs.collection('palabrasClaves', ref => ref.orderBy('nombre', 'asc')).valueChanges();
  }

  getTechOffersCounter() {
    return this.afs.collection('counters').doc('techOffers').valueChanges();
  }

  // ********************************************

  getTechOffersByArea(filter: any) {
    const query = this.afs.collection('ofertasTecnologicas', ref => ref.orderBy('createDate', 'desc').where('subGeneralArea', '==', filter));
    return query.valueChanges({ idField: 'id' });
  }

  getTechOffersByApplicationField(filter: any) {
    const queryOne = this.afs.collection('ofertasTecnologicas', ref => ref.orderBy('createDate', 'desc').where('applicationSubfield_1', '==', filter)).valueChanges({ idField: 'id' });
    const queryTwo = this.afs.collection('ofertasTecnologicas', ref => ref.orderBy('createDate', 'desc').where('applicationSubfield_2', '==', filter)).valueChanges({ idField: 'id' });
    const queryThree = this.afs.collection('ofertasTecnologicas', ref => ref.orderBy('createDate', 'desc').where('applicationSubfield_3', '==', filter)).valueChanges({ idField: 'id' });
    return combineLatest(queryOne, queryTwo, queryThree);
  }

  getTechOffersByDependencies(filter: any){
    const query = this.afs.collection('ofertasTecnologicas', ref => ref.orderBy('createDate', 'desc').where('groupData.dependencies', 'array-contains', filter));
    return query.valueChanges({ idField: 'id' });
  }

  // ********************************************

  getTechOffers(limit: number) {
    const query = this.afs.collection('ofertasTecnologicas', ref => ref.orderBy('createDate', 'desc').limit(limit));
    query.get().subscribe(snapshotChanges => {
      this.lastDoc = snapshotChanges.docs[snapshotChanges.docs.length - 1];
      this.firstDoc = snapshotChanges.docs[0];
    });
    return query.valueChanges({ idField: 'id' });
  }

  getNextTechOffers(limit: number) {
    const query = this.afs.collection('ofertasTecnologicas', ref => ref.orderBy('createDate', 'desc').startAfter(this.lastDoc).limit(limit));
    query.get().subscribe(snapshotChanges => {
      this.lastDoc = snapshotChanges.docs[snapshotChanges.docs.length - 1];
      this.firstDoc = snapshotChanges.docs[0];
    });
    return query.valueChanges({ idField: 'id' });
  }

  getPreviousTechOffers(limit: number) {
    const query = this.afs.collection('ofertasTecnologicas', ref => ref.orderBy('createDate', 'desc').endBefore(this.firstDoc).limitToLast(limit));
    query.get().subscribe(snapshotChanges => {
      this.lastDoc = snapshotChanges.docs[snapshotChanges.docs.length - 1];
      this.firstDoc = snapshotChanges.docs[0];
    });
    return query.valueChanges({ idField: 'id' });
  }

  getAllTechOffers() {
    return this.afs.collection('ofertasTecnologicas', ref => ref.orderBy('createDate', 'desc')).valueChanges({ idField: 'id' });
  }

  getTechOfferById(techOfferId: string) {
    return this.afs.collection('ofertasTecnologicas').doc(techOfferId).valueChanges();
  }

  getOfferTypes() {
    return this.afs.collection('tiposOferta', ref => ref.orderBy('nombre', 'asc')).valueChanges({ idField: 'id' });
  }

  getSubOfferTypes(offetTypeId: string) {
    return this.afs.collection('tiposOferta').doc(offetTypeId).collection('subtiposOferta').valueChanges({ idField: 'id' });
  }

  getAllDependencies() {
    return this.afs.collection('dependencias').valueChanges({ idField: 'id' });
  }

  getAllSubDependencies(fieldId: string) {
    return this.afs.collection('dependencias').doc(fieldId).collection('subDependencias').valueChanges({ idField: 'id' });
  }

  getAllGeneralAreas() {
    return this.afs.collection('areasCientificasGenerales').valueChanges({ idField: 'id' });
  }

  getSubGeneralAreas(generalAreaId: string) {
    return this.afs.collection('areasCientificasGenerales').doc(generalAreaId).collection('subAreasGenerales').valueChanges({ idField: 'id' });
  }

  getSpecificAreas(generalAreaId: string, subGeneralAreaId: string) {
    return this.afs.collection('areasCientificasGenerales').doc(generalAreaId).collection('subAreasGenerales').doc(subGeneralAreaId).collection('areasEspecificas').valueChanges({ idField: 'id' });
  }

  getApplicationFields() {
    return this.afs.collection('camposAplicacion', ref => ref.orderBy('codigo')).valueChanges({ idField: 'id' });
  }

  getApplicationSubfields(fieldId: string) {
    return this.afs.collection('camposAplicacion').doc(fieldId).collection('subcamposAplicacion', ref => ref.orderBy('codigo')).valueChanges({ idField: 'id' });
  }

  saveTechOffer(techOffer) {
    return from(this.afs.collection('ofertasTecnologicas').add(techOffer));
  }

  updateTechOffer(techOffer: TechOffer, docId: string) {
    return from(this.afs.collection('ofertasTecnologicas').doc(docId).set(techOffer));
  }

  deleteTechOffer(docId: string) {
    return this.afs.collection('ofertasTecnologicas').doc(docId).delete();
  }

  meiliSearch(keyword: string) {
    const url = 'http://104.131.45.54/indexes/ofertasTecnologicas/search';
    const body = {
      q: keyword,
      limit: 500
    };
    return this.http.post(url, body, {
      headers: {
        "X-Meili-API-Key": "meilikey_secret-vinculacionUNCuyo"
      }
    });

  }
}
