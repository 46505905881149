import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LinksService } from "src/app/service/links.service";
import { FormGroup } from "@angular/forms";
import { AngularFireStorage } from "@angular/fire/storage";
import { LinkModel } from "src/app/models/link.model";
import { take, takeUntil } from "rxjs/operators";

@Component({
  selector: "app-CreateOrEditLink",
  templateUrl: "./CreateOrEditLink.component.html",
  styleUrls: ["./CreateOrEditLink.component.css"],
})
export class CreateOrEditLinkComponent implements OnInit {
  formgroup: FormGroup;
  isEdit = false;
  parametro = false;
  linkId: string;
  fileName = "";
  file;
  showImage = false;
  imageSrc: string;

  constructor(
    private route: ActivatedRoute,
    private linksService: LinksService,
    private fb: FormBuilder,
    private router: Router,
    private storage: AngularFireStorage
  ) { }

  ngOnInit() {
    this.crearFormulario();
    this.route.params
      .pipe(
        take(1)
      )
      .subscribe((params) => {
        if (params.id) {
          this.isEdit = true;
          this.linkId = params.id;
          this.linksService.getLinkById(params.id)
            .pipe(
              take(1)
            )
            .subscribe((link: any) => {
              this.imageSrc = link.imageSrc;
              this.showImage = true;
              this.formgroup.patchValue({
                imageSrc: link.imageSrc,
                linkUrl: link.linkUrl,
                orden: link.orden,
              });
            });
        }
      });
  }

  onChange(e) {
    this.fileName = "imagesLinks/" + e.srcElement.files[0].name;
    this.file = e.srcElement.files[0];
  }

  saveDoc(imageUrl) {
    const newLink = { ...this.formgroup.value, imageSrc: imageUrl };

    this.linksService
      .addLink(newLink)
      .then(() => {
        this.router.navigate(["/gestionLinksDeInteres"]);
      })
      .catch((error) => {
        console.log("ERROR->", error);
      });
  }

  editLink(imageUrl) {
    let newLink;
    if (imageUrl) {
      newLink = { ...this.formgroup.value, imageSrc: imageUrl };
    } else {
      newLink = { ...this.formgroup.value };
    }

    this.linksService
      .updateLink(newLink, this.linkId)
      .then(() => {
        this.router.navigate(["/gestionLinksDeInteres"]);
      })
      .catch((error) => {
        console.log("ERROR->", error);
      });
  }


  onEditLink() {
    if (!this.showImage) {
      this.storage
        .upload(this.fileName, this.file)
        .then((res) => {
          const ref = this.storage.ref(this.fileName);
          ref
            .getDownloadURL()
            .pipe(take(1))
            .subscribe((imageUrl) => {
              this.editLink(imageUrl);
            });
          // console.log('resp', res)
        })
        .catch((error) => console.log(error));
    } else {
      this.editLink(null);
    }
  }

  crearFormulario() {
    this.formgroup = this.fb.group({
      orden: ['', [Validators.required]],
      linkUrl: ['', [Validators.required]],
      imageSrc: [''],
    });
  }

  //Upload file
  public onSaveLink() {
    this.storage
      .upload(this.fileName, this.file)
      .then((res) => {
        // this.saveDoc();
        const ref = this.storage.ref(this.fileName);
        ref
          .getDownloadURL()
          .pipe(take(1))
          .subscribe((imageUrl) => {
            this.saveDoc(imageUrl);
          });
        // console.log('resp', res)
      })
      .catch((error) => console.log(error));
  }

  //Edit file
  public editFile(fileName: string) {
    this.storage.ref(fileName);
  }

  onChangeImage() {
    this.showImage = false;
  }
}
