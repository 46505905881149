import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-tarjeta-grupo',
  templateUrl: './tarjeta-grupo.component.html',
  styleUrls: ['./tarjeta-grupo.component.css']
})
export class TarjetaGrupoComponent implements OnInit {

  @Input() grupo:any={};
  @Input() index:number;
 
  @Output() grupoSeleccionado: EventEmitter<number>;

  constructor(private router:Router) { 
    this.grupoSeleccionado= new EventEmitter();
  }

  ngOnInit() {
  }


  verGrupo(){
      // this.router.navigate(['/investigador', this.index]);
    
    this.grupoSeleccionado.emit(this.index);
  }

  verIntegrantes(termino:string){
    
    //console.log(termino);
    this.router.navigate(['/vistaIntegrantes', termino]);
  }

}
