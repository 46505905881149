import { Component, OnInit } from '@angular/core';
//import * as Slick from '../../../../assets/plugins/slick/slick.min.js';
//import * as slick from 'slick-carousel';
import * as $ from 'jquery';
// import $ from 'jquery';

import 'slick-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.less';
import 'slick-carousel/slick/slick-theme.scss';
import 'slick-carousel/slick/slick-theme.less';
import 'slick-carousel/slick/slick.scss';
import 'slick-carousel/slick/slick.min.js';
import 'slick-carousel/slick/slick.js';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})

export class SliderComponent implements OnInit {

  constructor() { 
    this.sliderScripts();
  }

  ngOnInit() {



    

}









sliderScripts(){

    $(document).ready(function() {
        (<any> $('.hero-slider')).slick({
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
            speed: 300,
            dots: true,
            arrows: true,
            fade: true,
            responsive: [{
                breakpoint: 600,
                settings: {
                    arrows: false
                }
            }]
        });
    
   
  
     // Item Slider
        (<any> $('.items-container')).not('.slick-initialized').slick({
      infinite: true,
      arrows: true,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [{
              breakpoint: 991,
              settings: {
                  slidesToShow: 2,
                  arrows: false
              }
          },
          {
              breakpoint: 525,
              settings: {
                  slidesToShow: 1,
                  arrows: false
              }
          }
      ]
  });


  });



}
}

