import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import {
  FormControl,
  NgForm,
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
} from "@angular/forms";
import { UsuarioModel } from "../../../models/usuario.model";
import { ValidadoresService } from "../../../service/validadores.service";
import { AuthService } from "../../../service/auth.service";
import { Router, ActivatedRoute } from "@angular/router";
import Swal from "sweetalert2";
import { debounceTime, finalize } from "rxjs/operators";

import { AngularFireStorage } from "@angular/fire/storage";
import { Observable } from "rxjs/internal/Observable";
import { PerfilService, Convocatoria } from "src/app/service/perfil.service";
import { GruposService, Grupo } from "src/app/service/grupos.service";
import { GrupoModel, integrante, Convenio } from "src/app/models/grupo.model";

@Component({
  selector: "app-crear-grupo",
  templateUrl: "./crear-grupo.component.html",
  styleUrls: ["./crear-grupo.component.css"],
  providers: [GruposService],
})
export class CrearGrupoComponent implements OnInit {
  grupo: GrupoModel = {
    convNombre1: "",
    convNombre2: "",
    convWeb1: "",
    convWeb2: "",
  };

  rolesList: string[] = [
    "Alumno",
    "Docente",
    "Investigador",
    "Personal de apoyo",
  ];
  usuario: UsuarioModel = {
    nombre: "",
    email: "",
    photoUrl: "",
  };
  forma: FormGroup;
  urlImage: Observable<string>;

  t = ["Becas de grado", "Becas de postgrado", "Proyectos de investigación"];
  conicet: string[];
  intereses: string[];
  interesSelected: string;
  dependeciaSelected: string;
  convocatorias: Convocatoria[];
  selectedRespuesta = 0;
  tipoProyectoSelected;
  disciplinas: string[];
  selectedDisciplina: string;
  photo: string;
  cargafoto = false;
  imagenSubir: File;
  imagenTemp: string | ArrayBuffer;
  uploadPercent: Observable<number>;
  parametro = false;
  dependencies: any[];
  userEmailCorrect: boolean;

  @ViewChild("imageUser", { static: false }) inputImageUser: ElementRef;

  constructor(
    private fb: FormBuilder,
    private validadores: ValidadoresService,
    private auth: AuthService,
    private router: Router,
    private storage: AngularFireStorage,
    private perfilService: PerfilService,
    private grupoService: GruposService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
    this.intereses = this.t.sort();
    this.crearFormulario();
  }

  ngOnInit() {
    this.grupo = new GrupoModel();
    this.disciplinas = this.grupoService.getDisciplinas();
    this.convocatorias = this.perfilService
      .getConvocatoria()
      .filter((item) => (item.respuesta = 1));

    this.grupoService.getAllDependencies().subscribe(
      (dependencies) => {
        this.dependencies = dependencies;
      },
      (err) => {
        console.log("Se produjo un error:", err);
      }
    );

    this.auth.isAuth().subscribe((user) => {
      if (user) {
        this.usuario = user;
        this.usuario.id = user.uid;
        this.grupoService.getGrupoFirebase(user.uid).subscribe((data) => {
          if (data) {
            this.grupo = data[0];
          }
        });
      }
    });

    this.activatedRoute.params.subscribe((params) => {
      if (params.id !== "") {
        this.parametro = true;
        // console.log('params :>> ', params);
        this.grupoService.getGrupoFirebase(params["id"]).subscribe((data) => {
          if (data) {
            this.grupo.urlPhoto = data.urlPhoto;
            this.grupo = data;
            this.grupo.id = params.id;
            this.grupo.urlPhoto = data.urlPhoto;

            if (this.grupo.urlPhoto) {
              this.photo = this.grupo.urlPhoto;
            }
            if (this.grupo.dependencias) {
              this.forma.controls.dependencias.setValue(
                this.grupo.dependencias
              );
            }

            if (data.idResponsable) {
              this.authService
                .getUserById(data.idResponsable)
                .subscribe((user: any) => {
                  this.forma.controls.responsableEmail.setValue(user.email);
                });
            }
          }
        });
      }
    });
  }

  get nombreNoValido() {
    if (this.grupo.nombre) {
      return this.forma.get("nombre").invalid;
    } else {
      return (
        this.forma.get("nombre").invalid && this.forma.get("nombre").touched
      );
    }
  }

  get direccionNoValido() {
    if (this.grupo.direccion) {
      return this.forma.get("direccion").invalid;
    } else {
      return (
        this.forma.get("direccion").invalid &&
        this.forma.get("direccion").touched
      );
    }
  }

  get correoNoValido() {
    return this.forma.get("email").invalid && this.forma.get("email").touched;
  }

  get telefonoNoValido() {
    if (this.grupo.telefono) {
      return this.forma.get("telefono").invalid;
    } else {
      return (
        this.forma.get("telefono").invalid && this.forma.get("telefono").touched
      );
    }
  }

  get cuilNoValido() {
    return this.forma.get("cuil").invalid && this.forma.get("cuil").touched;
  }

  get descripcionNoValido() {
    return (
      this.forma.get("descripcion").invalid &&
      this.forma.get("descripcion").touched
    );
  }

  get capacidadesNoValido() {
    return (
      this.forma.get("capacidad1").invalid &&
      this.forma.get("capacidad1").touched
    );
  }

  get fotoNoValido() {
    return this.forma.get("urlPhoto").invalid;
  }

  get convNombre1NoValido() {
    return this.forma.get("convNombre1").invalid;
  }

  get convNombre2NoValido() {
    return this.forma.get("convNombre2").invalid;
  }

  get convWeb1NoValido() {
    return this.forma.get("convWeb1").invalid;
  }

  get convWeb2NoValido() {
    return this.forma.get("convWeb2").invalid;
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  agregarRol(roles: string[]) {
    this.clearFormArray(this.rolesItems);
    roles.forEach((element) => {
      this.rolesItems.push(this.fb.control(element));
    });
  }

  get rolesItems() {
    return this.forma.get("roles") as FormArray;
  }

  get disciplinaNoValido() {
    return (
      this.forma.get("disciplina").invalid &&
      this.forma.get("disciplina").touched
    );
  }

  get validacionDependencias() {
    return (
      this.forma.get("dependencias").invalid &&
      this.forma.get("dependencias").touched
    );
  }

  crearFormulario() {
    this.forma = this.fb.group(
      {
        nombre: ["", [Validators.required]],
        direccion: ["", [Validators.required]],
        // email: ['', [Validators.required, Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$")]],
        responsableEmail: [
          "",
          [
            Validators.required,
            Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
          ],
        ],
        telefono: [
          "",
          [
            Validators.required,
            Validators.min(100000000),
            Validators.max(99999999999999),
          ],
        ],
        sitioWeb: [""],
        descripcion: ["", [Validators.required, Validators.maxLength(500)]],
        urlPhoto: [""],
        dependencias: [[], [Validators.required]],
        disciplina: [""],
        idResponsable: [""],
        capacidad1: ["", [Validators.required, Validators.maxLength(40)]],
        capacidad2: ["", [Validators.maxLength(40)]],
        capacidad3: ["", [Validators.maxLength(40)]],
        convNombre1: ["", [Validators.maxLength(40)]],
        convNombre2: ["", [Validators.maxLength(40)]],
        convWeb1: ["", [Validators.maxLength(40)]],
        convWeb2: ["", [Validators.maxLength(40)]],
      },
      {
        validators: this.validadores.validateDisciplina("disciplina"),
      }
    );

    this.forma.controls.responsableEmail.valueChanges
      .pipe(debounceTime(300))
      .subscribe((email) => {
        if (this.forma.controls.responsableEmail.valid) {
          this.grupoService.getUserByEmail(email).subscribe((users: any) => {
            if (users && users.length) {
              this.userEmailCorrect = true;
              this.forma.controls.idResponsable.setValue(users[0].id);
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 6500,
                timerProgressBar: true,
              });

              Toast.fire({
                icon: "success",
                title: "Usuario asignado correctamente!",
              });
            } else {
              this.userEmailCorrect = false;
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 6500,
                timerProgressBar: true,
              });

              Toast.fire({
                icon: "error",
                title:
                  "No se encontraron usuarios con el email ingresado. Por favor, intente nuevamente.",
              });
            }
          });
        }
      });
  }

  guardar() {
    if (this.cargafoto === true || !this.photo) {
      Swal.fire({
        icon: "error",
        title: "Foto no cargada",
        text: "Por favor actualice foto..",
      });
      return;
    }
    this.forma.value.urlPhoto = this.photo;

    if (!this.userEmailCorrect) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 6500,
        timerProgressBar: true,
      });

      Toast.fire({
        icon: "error",
        title:
          "No se encontraron usuarios con el email ingresado. Por favor, intente nuevamente.",
      });
      return;
    }

    if (this.forma.invalid) {
      return Object.values(this.forma.controls).forEach((control) => {
        Swal.fire({
          icon: "error",
          title: "Formulario no válido",
          text: "Por favor corrobore los campos..",
        });
        control.markAsTouched();
      });
    } else {
      let group: GrupoModel = new GrupoModel();
      group = this.forma.value;
      group.capacidad2 = "";
      group.capacidad3 = "";
      group.capacidades = [];
      group.convenios = [];
      group.capacidades.push(this.forma.value.capacidad1);
      if (this.forma.value.capacidad2) {
        group.capacidades.push(this.forma.value.capacidad2);
      }

      if (this.forma.value.capacidad3) {
        group.capacidades.push(this.forma.value.capacidad3);
      }

      if (this.forma.value.convNombre1 && this.forma.value.convWeb1) {
        const convenio: Convenio = {
          nombre: this.forma.value.convNombre1,
          web: this.forma.value.convWeb1,
        };
        group.convenios.push(convenio);
      } else {
        (this.forma.value.convNombre1 = ""), (this.forma.value.convWeb1 = "");
      }
      if (this.forma.value.convNombre2 && this.forma.value.convWeb2) {
        const convenio2: Convenio = {
          nombre: this.forma.value.convNombre2,
          web: this.forma.value.convWeb2,
        };
        group.convenios.push(convenio2);
      } else {
        (this.forma.value.convNombre2 = ""), (this.forma.value.convWeb2 = "");
      }

      const user: integrante = {
        user: this.forma.controls.idResponsable.value,
        rol: "Responsable",
      };
      group.integrantes = [];
      group.integrantes.push(user);
      // console.log('imprimo el param antes de entrar al ifelse', this.parametro);
      if (this.parametro === false) {
        //  console.log('this.parametro :>> ', this.parametro);
        this.grupoService.addGroup(group);
      } else {
        //  console.log('aqui pongo el else del param :>> ', this.parametro);
        group.id = this.grupo.id;
        this.grupoService.updateGroup(group);
      }

      this.router.navigate(["/grupos-investigacion"]);
    }
  }

  onUpload(archivo: File) {
    if (!archivo) {
      return;
    }
    if (archivo.type.indexOf("image") < 0) {
      Swal.fire(
        "Sólo imágenes",
        "El archivo seleccionado no es una imagen",
        "error"
      );
      return;
    }
    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL(archivo);

    reader.onloadend = () => (this.imagenTemp = reader.result);
    this.imagenSubir = archivo;
    const id = Math.random().toString(36).substring(2);
    const file = archivo;
    const filePath = `grupos/group${id}`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();
    task
      .snapshotChanges()
      .pipe(finalize(() => (this.urlImage = ref.getDownloadURL())))
      .subscribe();
    this.cargafoto = true;
  }

  actualizarImg() {
    this.photo = this.inputImageUser.nativeElement.value;
    this.cargafoto = false;
  }

  actualizarPerfil() {
    this.auth.isAuth().subscribe((user) => {
      if (user) {
        user
          .updateProfile({
            displayName: "",
            photoURL: this.inputImageUser.nativeElement.value,
          })
          .then(function () {
            console.log("");
          })
          .catch((error) => console.log("error", error));
      }
    });
  }

  seleccionInteres(intereses: string[]) {
    this.clearFormArray(this.interesesItems);
    intereses.forEach((element) => {
      this.interesesItems.push(this.fb.control(element));
    });
  }

  get interesesItems() {
    return this.forma.get("intereses") as FormArray;
  }

  get dependenciasItems() {
    return this.forma.get("dependencias") as FormArray;
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.forma.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  seleccionTipoProyecto(tipo: string) {
    this.tipoProyectoSelected = tipo;
  }

  onSelect(idRespuesta) {
    this.selectedRespuesta = idRespuesta;
    // this.convocatorias = this.perfilService.getConvocatoria().filter((item) => item.respuesta == idRespuesta);
  }

  seleccionDisciplina(disciplina: number) {
    // console.log('disciplina :>> ', disciplina);
    // if (disciplina == 0 ) {
    //   this.forma.get('disciplina').setErrors({invalido:true});
    // }else{
    //   this.forma.get('disciplina').setErrors(null);
    // this.selectedDisciplina = disciplina.toString();
    // }
  }

  eliminar() {
    Swal.fire({
      title: "¿Esta seguro que desea eliminar el grupo?",
      text: "El cambio es irreversible",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.value) {
        this.grupoService
          .deleteGrupoById(this.grupo.id)
          .then(() => this.router.navigate(["/grupos-investigacion"]));
        Swal.fire("Eliminado", "El grupo ha sido eliminado.", "success");
      }
    });
  }
}
