import { Pais } from 'src/app/service/paises.service';
export class ViajeModel{
    id ?: string;
    userId?: string;
    userName?: string;
    codigoPais?: string;
    nombrePais?: string;
    dependenciaDestino?: string;
    anioDeViaje?: string;
    videoUrl?: string;
}


