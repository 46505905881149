import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { ViajeModel } from 'src/app/models/viaje.model';




@Injectable({
  providedIn: 'root'
})
export class ViajeService {

    private viajesCollection: AngularFirestoreCollection<ViajeModel>;
    private viajes: Observable<ViajeModel[]>;
    private viajeDoc: AngularFirestoreDocument<ViajeModel>;
    private viaje: Observable<ViajeModel>;
    public selectedUser: UsuarioModel = {
      id: null
    };

    constructor(private afs: AngularFirestore) { 
        
    }
    id ?: string;
    userId?: string;
    codigoPais?: string;
    dependenciaDestino?: string;
    anioDeViaje?: string;
    videoUrl?: string;
  
    getviajesFirebase(){
        this.viajesCollection = this.afs.collection<ViajeModel>('viajes');
        return this.viajes = this.viajesCollection.snapshotChanges()
      .pipe(map(changes => {
        return changes.map(action => {
          const data = action.payload.doc.data();
          data.id = action.payload.doc.id;
          return data;
        });
      }));
    }

    

    

    getMovilizadoFirebase(idx: string) {
        this.viajeDoc = this.afs.doc<ViajeModel>(`viajes/${idx}`);
        return this.viaje = this.viajeDoc.snapshotChanges().pipe(map(action => {
          if (action.payload.exists == false) {
            console.log('return null ');
            return null;
          } else {
            const data = action.payload.data();
            data.id = action.payload.data().id;
            return data;
          }
        }));
      }


    getViajesPorPais(codPais: string) {
      this.viajesCollection = this.afs.collection<ViajeModel>('viajes', ref => ref.where('codigoPais', '==', codPais));
      return this.viajes = this.viajesCollection.snapshotChanges()
          .pipe(map(changes => {
            return changes.map(action => {
              const data = action.payload.doc.data() as ViajeModel;
              data.id = action.payload.doc.id;
              return data;
            });
          }));
      }


      addViaje(viaje: ViajeModel): void {
        this.viajesCollection = this.afs.collection('viajes');
        this.viajesCollection.add(viaje);
      }



      // updateGroup(grupo: GrupoModel): void {
      //   let idGrupo = grupo.id;
      //   this.docGrupo = this.afs.doc<GrupoModel>(`grupos/${idGrupo}`);
      //   this.docGrupo.update(grupo);
      // }
    
    
    


}

