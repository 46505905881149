import { Component, OnInit } from '@angular/core';
import { EquipamientoService } from 'src/app/service/equipamiento.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipoModel } from 'src/app/models/equipo.model';
import { ReservaService } from 'src/app/service/reserva.service';
import { FormGroup, NgForm } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { string } from '@amcharts/amcharts4/core';
import Swal from 'sweetalert2';



const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};



@Component({
  selector: 'app-vista-equipo',
  templateUrl: './vista-equipo.component.html',
  styleUrls: ['./vista-equipo.component.css']
})
export class VistaEquipoComponent implements OnInit {

  equipo: EquipoModel= new EquipoModel();
  forma: FormGroup;
  constructor(private equipoService: EquipamientoService, private activatedRoute: ActivatedRoute, public reservaService: ReservaService,
              private datePipe: DatePipe, private http: HttpClient, private router: Router) {
   

   }

   mailUrl = "https://us-central1-vinculacioncientifica-fc666.cloudfunctions.net/sendMail";
  
  ngOnInit() {
   

    this.activatedRoute.params.subscribe( params => {
      if (params.id !== "") {

        this.equipoService.getEquipoFirebase(params['id']).subscribe(data => {
          this.equipo = data;
        });
      }
    });

}
onClear() {
  this.reservaService.form.reset();
  this.reservaService.initializeFormGroup();

}
onSubmit(){
  this.reservaService.form.get('nombreEquipo').setValue(this.equipo.equipamiento);
  this.reservaService.form.get('nroInventario').setValue(this.equipo.codigo);
  this.reservaService.form.get('dependencia').setValue(this.equipo.dependencia[0]);
  this.reservaService.form.get('mailEncargado').setValue(this.equipo.email);
  this.reservaService.form.get('fecha').setValue(this.datePipe.transform(this.reservaService.form.get('fecha').value, 'dd-MM-yyyy'))
  if (this.reservaService.form.invalid) {
      
    return Object.values(this.reservaService.form.controls).forEach( control => {
      control.markAsTouched();
    });
  } else {
    this.sendMessage().subscribe();
    this.router.navigate(['/equipos']);
      Swal.fire(
        'Enviado',
        'Solicitud enivada con éxito.',
        'success'
      );
    

  }
}

private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead
    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);
    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

sendMessage(): Observable<any> {
  
  // console.log('this.reservaService.form.value :>> ', this.reservaService.form.value);

  return this.http.post<any>(this.mailUrl ,this.reservaService.form.value, httpOptions).pipe(
    tap(
      message => console.log(message)
    ),
    catchError(this.handleError('Sending Message', []))
  );

  // this.http.post(this.mailUrl,this.reservaService.form.value, httpOptions).toPromise()
  // .then( res => {
  //   this.router.navigate(['/equipos']);
      Swal.fire(
        'Enviado',
        'Solicitud enivada con éxito.',
        'success'
      );
  // })
  // .catch(err => {
  //   console.log(err)
  // });

  // this.http.post(this.mailUrl,this.reservaService.form.value, httpOptions).subscribe();
}

}
