import { Component, OnInit } from '@angular/core';
import {GruposService} from '../../../service/grupos.service';
import {GrupoModel} from '../../../models/grupo.model';
import Swal from 'sweetalert2';
import {Router} from '@angular/router';
import {MatTableDataSource} from '@angular/material/table';
import {NgxSpinnerService} from 'ngx-spinner';
import {AuthService} from '../../../service/auth.service';

@Component({
  selector: 'app-grupos-investigacion',
  templateUrl: './grupos-investigacion.component.html',
  styleUrls: ['./grupos-investigacion.component.css']
})
export class GruposInvestigacionComponent implements OnInit {
  grupoInvestigacion = new GrupoModel();
  gruposInvestigacion: GrupoModel[];
  dataSource = null;

  displayedColumns: string[] = ['nombre', 'dependencia', 'disciplina', 'responsable', 'acciones'];



  constructor(private guposService: GruposService,
              private router: Router,
              private spinnerService: NgxSpinnerService,
              private authService: AuthService) { }

  ngOnInit() {
    this.authService.verificaRol('superadmin').then((verificado: any) => {
      if (verificado) {
        this.cargarGruposInvestigacion();
      } else {
        this.router.navigateByUrl('/home');
      }
    });

  }

  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filtro.trim().toLowerCase();
  }

  cargarGruposInvestigacion() {
    this.spinnerService.show();
    this.guposService.getGruposInvestigacion().subscribe(
      (resp) => {
        this.spinnerService.hide();
        this.gruposInvestigacion = resp;
        this.dataSource = new MatTableDataSource(this.gruposInvestigacion);
      },
      (err) => {
        console.log('Se produjo un error:', err);
      }
    );

  }

  onEdit(grupoInvestigacion) {
    this.router.navigate(['creargrupo', grupoInvestigacion.id]);
  }

  onDelete(grupoInvestigacion) {
    this.grupoInvestigacion = grupoInvestigacion;
    Swal.fire({
      title: '¿Esta seguro que desea eliminar este Grupo de Investigacion?',
      text: 'El cambio es irreversible',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar',
    }).then((result) => {
      if (result.value) {
        this.guposService
          .deleteGrupoById(this.grupoInvestigacion.id)
          .then(() => this.router.navigate(['/grupos-investigacion']));
        Swal.fire('Eliminado', 'El Grupo de Investigacion ha sido eliminado.', 'success');
      }
    });
  }

}
