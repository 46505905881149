import { Injectable } from "@angular/core";
import { GrupoModel } from "../models/grupo.model";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { EquipoModel } from "src/app/models/equipo.model";

@Injectable({
  providedIn: "root",
})
export class EquipamientoService {
  equiposDocumentos: AngularFirestoreCollection<EquipoModel>;
  private equipoCollection: AngularFirestoreCollection<EquipoModel>;
  private grupo: Observable<GrupoModel[]>;
  private equipoI: Observable<EquipoModel>;
  private grupos;
  private equiposObs: Observable<EquipoModel[]>;
  private docEquipo: AngularFirestoreDocument<EquipoModel>;

  constructor(private afs: AngularFirestore) {}
  
  getAllDependencies() {
    return this.afs.collection('dependencias').valueChanges();
  }

  getEquipoFirebaseEncargado(idEncargado: string) {
    this.equiposDocumentos = this.afs.collection<EquipoModel>(
      "equipamientos",
      (ref) => ref.where("idResponsable", "==", idEncargado)
    );
    return (this.equiposObs = this.equiposDocumentos.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as EquipoModel;
          data.id = action.payload.doc.id;

          return data;
        });
      })
    ));
  }

  getEquipoFirebase(idx: string) {
    this.docEquipo = this.afs.doc<EquipoModel>(`equipamientos/${idx}`);
    return (this.equipoI = this.docEquipo.snapshotChanges().pipe(
      map((action) => {
        if (action.payload.exists == false) {
          return null;
        } else {
          const data = action.payload.data() as EquipoModel;
          data.id = action.payload.id;

          return data;
        }
      })
    ));
  }

  addEquipo(equipo: EquipoModel): void {
    this.equipoCollection = this.afs.collection("equipamientos");
    this.equipoCollection.add(equipo);
  }

  getAllEquipos() {
    this.equipoCollection = this.afs.collection<EquipoModel>("equipamientos");
    return (this.equiposObs = this.equipoCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as EquipoModel;
          data.id = action.payload.doc.id;
          return data;
        });
      })
    ));
  }

  updateEquipo(equipo: EquipoModel): void {
    const idEquipo = equipo.id;
    this.docEquipo = this.afs.doc<EquipoModel>(`equipamientos/${idEquipo}`);
    this.docEquipo.update(equipo);
  }

  deleteEquipById(equipo: EquipoModel) {
    this.equipoCollection = this.afs.collection<EquipoModel>("equipamientos");
    return this.equipoCollection.doc(equipo.id).delete();
  }
}
