// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// tslint:disable-next-line:one-variable-per-declaration
export const environment = {
  production: false,

  // Your web app's Firebase configuration
  firebaseConfig: {
    apiKey: "AIzaSyAANB9g_Fl3zm8CBC6iO3nu4UB4lkafbDg",
    authDomain: "vinculacioncientifica-fc666.firebaseapp.com",
    databaseURL: "https://vinculacioncientifica-fc666.firebaseio.com",
    projectId: "vinculacioncientifica-fc666",
    storageBucket: "vinculacioncientifica-fc666.appspot.com",
    messagingSenderId: "738380922610",
    appId: "1:738380922610:web:0d5a4d405d017ca8d95a6b",
    measurementId: "G-QRN0X8J8GS"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 * 
 * 
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
