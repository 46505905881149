import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../../service/auth.service';
import {ExporterService} from '../../../../service/exporter.service';
import {EquipamientoService} from '../../../../service/equipamiento.service';
import {ViajeService} from '../../../../service/viajes.service';

interface Viaje {
  Nombre_Apellido?: string;
  Anio?: string;
  Pais_Destino?: string;
  Dependencia_Destino?: string;
}


@Component({
  selector: 'app-reporte-viajes',
  templateUrl: './reporte-viajes.component.html',
  styleUrls: ['./reporte-viajes.component.css']
})
export class ReporteViajesComponent implements OnInit {
  dataSourceViajes: Viaje[] = [];
  displayedColumns: string[] = ['Nombre_Apellido', 'Anio', 'Pais_Destino', 'Dependencia_Destino'];

  constructor(private authService: AuthService,
              private excelService: ExporterService,
              private equipamientoService: EquipamientoService,
              private viajesService: ViajeService) { }

  ngOnInit() {
    this.cargarViajes();

  }

  cargarViajes() {

    this.viajesService.getviajesFirebase().subscribe( viajes => {
      const viajesTemp: Viaje[] = [];
      if (viajes) {

        for (const viaje of viajes) {

          viajesTemp.push({
            Nombre_Apellido: viaje.userName,
            Anio: viaje.anioDeViaje,
            Pais_Destino: viaje.nombrePais,
            Dependencia_Destino: viaje.dependenciaDestino
          });

        }
        this.dataSourceViajes =  Object.values(viajesTemp);
      }

    });



  }

  generarReporte() {
    this.excelService.exportToExcel(this.dataSourceViajes, 'detalle_viajes_db');
  }
}
