import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormControl, NgForm, FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { UsuarioModel } from '../../../models/usuario.model';
import { ValidadoresService } from '../../../service/validadores.service';
import { AuthService } from '../../../service/auth.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import {finalize} from 'rxjs/operators';

import {AngularFireStorage} from '@angular/fire/storage';
import { Observable } from 'rxjs/internal/Observable';
import { PerfilService, Convocatoria } from 'src/app/service/perfil.service';
import { GruposService } from 'src/app/service/grupos.service';
import { GrupoModel, integrante } from 'src/app/models/grupo.model';

@Component({
  selector: 'app-perfil-investigador',
  templateUrl: './perfil-investigador.component.html',
  styleUrls: ['./perfil-investigador.component.css'],
  providers: [PerfilService]
})
export class PerfilInvestigadorComponent implements OnInit {


  usuario: UsuarioModel = {
    nombre: '',
    email: '',
    photoUrl: ''
  };
  forma: FormGroup;

  t = ['No es de mi interés','Becas de grado', 'Becas de postgrado', 'Proyectos de investigación'];
  intereses: string[];
  tipoProyectoSelected;
  mostrarProyectosAcreditados = false;
  selectedRespuesta = 0;
  convocatorias: string[];
  disciplinas: string[];
  selectedDisciplina: string;



  constructor( private fb: FormBuilder, private validadores: ValidadoresService, private auth: AuthService, private router: Router,
               private storage: AngularFireStorage, private perfilService: PerfilService, private grupoService: GruposService) {
     this.intereses= this.t.sort();

     this.crearFormulario();


}

  ngOnInit() {
    this.disciplinas= this.perfilService.getDisciplinas()


    // this.convocatorias = this.perfilService.getConvocatoria().filter((item) => item.respuesta = 1);
    this.convocatorias = this.perfilService.getConvocatorias().sort();

    this.auth.isAuth().subscribe(user => {
      if (user) {
        this.usuario.id = user.uid;

        this.auth.getUserData(user.uid).valueChanges().subscribe(data=>{
          if (data) {
            this.usuario= data;
            this.usuario.id = user.uid;
            this.selectedRespuesta = Number(this.usuario.participacionProyecto);
            // if (this.usuario.tipoDeProyecto) {
            //   this.mostrarProyectosAcreditados = true;
            //   this.tipoProyectoSelected=this.usuario.tipoDeProyecto;
            // }

            if (this.usuario.tipoDeProyectos && this.usuario.tipoDeProyectos.length > 0) {
              this.mostrarProyectosAcreditados = true;
              // this.tipoProyectoSelected=this.usuario.tipoDeProyecto;
            }
            if (this.usuario.disciplina) {
              this.selectedDisciplina= this.usuario.disciplina;
            }


            if (this.usuario.intereses) {


              this.usuario.intereses.forEach(element => {
                this.interesesItems.push(this.fb.control(element));
               });

            }

            if (this.usuario.tipoDeProyectos) {


              this.usuario.tipoDeProyectos.forEach(element => {
                this.tipoDeProyectosItems.push(this.fb.control(element));
               });

            }

          }



       });

      }
    });
  }



  crearFormulario(){
    this.forma = this.fb.group({

      descripcion: ['', [Validators.required, Validators.maxLength(400)] ],
      linkedin: [''],
      cvar: [''],
      researchgate: [''],
      sitiopropio: [''],
      micrositio: [''],
      intereses: this.fb.array([]),
      tipoDeProyectos: this.fb.array([], [this.validadores.excesoConvocatorias]),
      participacionProyecto: [''],
      // tipoDeProyecto: [''],
      disciplina: [''],


    }, {

      validators: [this.validadores.validateDisciplina('disciplina')]
      // validators: this.validadores.passwordsIguales('pass1', 'pass2')
    });
  }

  initializeFormGroup() {
    this.forma.setValue({
      descripcion: '',
      linkedin: '',
      cvar: '',
      researchgate: '',
      sitiopropio: '',
      micrositio: '',
      intereses: [],
      tipoDeProyectos: [],
      participacionProyecto: '',
      // tipoDeProyecto: '',
      disciplina: ''

    });
  }


  get descripcionNoValido() {
    return this.forma.get('descripcion').invalid && this.forma.get('descripcion').touched;
  }
  get disciplinaNoValido() {
    return this.forma.get('disciplina').invalid && this.forma.get('disciplina').touched;
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  seleccionInteres(intereses: string[]){

    this.clearFormArray(this.interesesItems);
    intereses.forEach(element => {
      this.interesesItems.push(this.fb.control(element));
     });





   }

   get validacionTipoDeProyectos() {

    return this.forma.get('tipoDeProyectos').invalid;

}

   seleccionTipoDeProyectos(tipoDeProyectos: string[]){

    this.clearFormArray(this.tipoDeProyectosItems);
    tipoDeProyectos.forEach(element => {
      this.tipoDeProyectosItems.push(this.fb.control(element));
     });





   }

   get interesesItems(){
      return this.forma.get('intereses') as FormArray;
   }

   get tipoDeProyectosItems(){
    return this.forma.get('tipoDeProyectos') as FormArray;
 }



  public findInvalidControls() {
    const invalid = [];
    const controls = this.forma.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
}
onSelect(idRespuesta) {
  this.selectedRespuesta=idRespuesta;
  if (idRespuesta == 1) {
    this.mostrarProyectosAcreditados = true;
    this.usuario.tipoDeProyecto = "0"
  } else{
    this.mostrarProyectosAcreditados = false;
  }
  //this.convocatorias = this.perfilService.getConvocatoria().filter((item) => item.respuesta == idRespuesta);
}

seleccionTipoProyecto(tipo: string){

  this.tipoProyectoSelected = tipo;


}




guardar(){

  if (!this.forma.value.linkedin) {
    this.forma.get('linkedin').setValue('');

  }

  if (!this.forma.value.cvar) {
    this.forma.get('cvar').setValue('');

  }

  if (!this.forma.value.researchgate) {
    this.forma.get('researchgate').setValue('');

  }

  if (!this.forma.value.sitiopropio) {
    this.forma.get('sitiopropio').setValue('');

  }


  if (!this.forma.value.micrositio) {
    this.forma.get('micrositio').setValue('');

  }




  this.forma.value.participacionProyecto=this.selectedRespuesta;

  if (this.mostrarProyectosAcreditados == false) {
    // this.forma.value.tipoDeProyecto = '';
    this.forma.value.tipoDeProyectos = [];

  }
  // else {
  //   this.forma.value.tipoDeProyectos = this.tipoProyectoSelected;
  // }

  // console.log('Aqui va el control invalido')
  // console.log(this.findInvalidControls());
  // console.log(this.forma);
  // console.log(this.forma.valid);


  if (this.forma.invalid) {
    return Object.values(this.forma.controls).forEach( control => {
      control.markAsTouched();
      Swal.fire({
        icon: 'error',
        title: 'Formulario no válido',
        text: 'Por favor corrobore los campos..'

      });
    });
  } else {

    this.auth.updateUserProfileInvestigator(this.forma, this.usuario).
    then((res) => {
      this.router.navigate(['/home']);
    }).catch(err => {


      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: err.message
      });
    });


  }


}


seleccionDisciplina(disciplina: number){
//   console.log('disciplina :>> ', disciplina);
//   if (disciplina == 0 ) {
//     this.forma.get('disciplina').setErrors({invalido:true});
//   }else{
//     this.forma.get('disciplina').setErrors(null);

//   this.selectedDisciplina = disciplina.toString();
// }
}

}
