import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ViajeService } from "src/app/service/viajes.service";
import { InteractiveMapComponent } from "../../interactive-map/interactive-map.component";

@Component({
  selector: "app-service",
  templateUrl: "./movilizados.component.html",
  styleUrls: ["./movilizados.component.css"],
})
export class ServiceComponent implements OnInit {
  @ViewChild("mapa", { static: true }) viajes: InteractiveMapComponent;
  public res = [];

  constructor(private viajeService: ViajeService) {}

  ngOnInit() {
    this.viajeService.getviajesFirebase().subscribe((data) => {
      this.res = data;
      // this.viajes.viajes = data;
    });
  }
}
