import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { UsuarioModel } from "../../../models/usuario.model";
import { ValidadoresService } from "../../../service/validadores.service";
import { AuthService } from "../../../service/auth.service";
import { Router } from "@angular/router";
import Swal from "sweetalert2";
import { finalize } from "rxjs/operators";
import { AngularFireStorage } from "@angular/fire/storage";
import { Observable } from "rxjs/internal/Observable";
import { PerfilService } from "src/app/service/perfil.service";
import { GruposService } from "src/app/service/grupos.service";
import { GrupoModel, integrante } from "src/app/models/grupo.model";
import { Pais, PaisesService } from "src/app/service/paises.service";
import { ViajeModel } from "src/app/models/viaje.model";
import { ViajeService } from "src/app/service/viajes.service";
import { SearchCountryField, TooltipLabel, CountryISO } from "ngx-intl-tel-input";
import { DataShearingService } from "src/app/service/data-shearing.service";
import { NgxImageCompressService } from "ngx-image-compress";
import {RolesService} from '../../../service/roles.service';

@Component({
  selector: "app-perfil",
  templateUrl: "./perfil.component.html",
  styleUrls: ["./perfil.component.css"],
  providers: [PerfilService, PaisesService],
})
export class PerfilComponent implements OnInit {
  grupo: GrupoModel = {
    convNombre1: "",
    convNombre2: "",
    convWeb1: "",
    convWeb2: "",
  };

  dependencies: any[];
  rolesList: any[] = [];

  // rolesList: string[] = [
  //   'Estudiante de grado',
  //   'Estudiante de pre-grado',
  //   'Estudiante de posgrado',
  //   'Egresado',
  //   'Docente-Investigador',
  //   'Docente de pre-grado',
  //   'Docente de grado',
  //   'Docente de posgrado',
  //   'Investigador',
  //   'Gestor',
  // ];

  isInvestigador = false;
  usuario: UsuarioModel = new UsuarioModel();
  data = false;
  forma: FormGroup;
  urlImage: Observable<string>;
  grupos: Observable<GrupoModel[]>;
  groups: GrupoModel[];
  grupoPertenece: string;
  grupoElegido: "0: undefined";
  dep: string[];
  conicet: string[];
  dependeciaSelected: string;
  selectedRespuesta = 0;
  selectedRespuestaPais: number = 0;
  photo: string;
  cargafoto = false;
  imagenSubir: File;
  imagenTemp: string | ArrayBuffer;
  uploadPercent: Observable<number>;
  rolInvestigador: boolean;
  paises: Pais[] = [];
  paisesSorted: Pais[] = [];
  paisSelected: Pais;
  mostrarPaises = false;

  // Validacion Teléfono
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Argentina,
    CountryISO.UnitedKingdom,
  ];

  @ViewChild("imageUser", { static: false }) inputImageUser: ElementRef;

  constructor(
    private fb: FormBuilder,
    private validadores: ValidadoresService,
    private auth: AuthService,
    private router: Router,
    private storage: AngularFireStorage,
    private grupoService: GruposService,
    private paisService: PaisesService,
    private viajeService: ViajeService,
    private dataSharingService: DataShearingService,
    private imageCompress: NgxImageCompressService,
    private rolesServices: RolesService
  ) {
    this.crearFormulario();
  }

  ngOnInit() {


    this.rolesServices.getAllRoles().subscribe( (roles: any[]) => {
      for (const rol of roles) {
        if ( rol.nombre && rol.nombre !== 'superadmin') {
          this.rolesList.push({nombre: rol.nombre, id: rol.id});
        }
      }
    });

    this.getCurrentUser();
    this.paises = this.paisService.paises;

    this.grupoService.getAllDependencies().subscribe(
      (dependencies) => {
        this.dependencies = dependencies;
      },
      (err) => {
        console.log("Se produjo un error:", err);
      }
    );

    this.grupos = this.grupoService.getAllGroups();
    this.grupos.subscribe((data) => {
      this.groups = data;
    });

    // this.convocatorias= this.perfilService.getConvocatoria();

    this.auth.isAuth().subscribe((user) => {
      if (user) {
        this.usuario.id = user.uid;
        this.usuario.email = user.email;
        // this.usuario.cuil= ' ';

        this.auth
          .getUserData(user.uid)
          .valueChanges()
          .subscribe((data) => {
            if (data) {
              this.usuario = data;
              this.usuario.id = user.uid;
              this.usuario.email = user.email;

              if (this.usuario.photoUrl) {
                this.data = true;
                this.photo = this.usuario.photoUrl;
              }

              if (this.usuario.dependencias) {
                this.forma.controls.dependencias.setValue(
                  this.usuario.dependencias
                );
              }

              if (this.usuario.rol) {
                this.usuario.rol.forEach((element) => {
                  this.rolesItems.push(this.fb.control(element));
                });
              }
              if (!this.usuario.grupoPertenece) {
                this.usuario.grupoPertenece = "0: undefined";
              }
              this.grupoPertenece = this.usuario.grupoPertenece;

              if (this.usuario.telefono && this.forma) {
                this.forma.controls["telefono"].setValue(
                  this.usuario.telefono.nationalNumber
                );
              }
            }
          });
      } else {
        if (!this.usuario.grupoPertenece) {
          this.usuario.grupoPertenece = "0: undefined";
        }
      }
    });
  }

  getCurrentUser() {
    this.auth.isAuth().subscribe((auth) => {
      if (auth) {
        this.auth
          .getUserData(auth.uid)
          .valueChanges()
          .subscribe((data: any) => {
            if (data && data.rol) {
              if (
                data.rol.includes("Docente") ||
                data.rol.includes("Investigador") ||
                data.rol.includes("Docente-Investigador")
              ) {
                this.isInvestigador = true;
              } else {
                this.isInvestigador = false;
              }
            }
          });
      }
    });
  }

  get nombreNoValido() {
    if (this.usuario.nombre) {
      return this.forma.get("nombre").invalid;
    } else {
      return (
        this.forma.get("nombre").invalid && this.forma.get("nombre").touched
      );
    }
  }
  get apellidoNoValido() {
    if (this.usuario.apellido) {
      return this.forma.get("apellido").invalid;
    } else {
      return (
        this.forma.get("apellido").invalid && this.forma.get("apellido").touched
      );
    }
  }

  get gradoAcademicoNoValido() {
    if (this.usuario.gradoAcademico) {
      return this.forma.get("gradoAcademico").invalid;
    } else {
      return (
        this.forma.get("gradoAcademico").invalid &&
        this.forma.get("gradoAcademico").touched
      );
    }
  }

  get correoNoValido() {
    return this.forma.get("email").invalid && this.forma.get("email").touched;
  }

  get telefonoNoValido() {
    if (this.usuario.telefono) {
      return this.forma.get("telefono").invalid;
    } else {
      return (
        this.forma.get("telefono").invalid && this.forma.get("telefono").touched
      );
    }
  }
  get cuilNoValido() {
    return this.forma.get("cuil").invalid && this.forma.get("cuil").touched;
  }

  get validacionDependencias() {
    return (
      this.forma.get("dependencias").invalid &&
      this.forma.get("dependencias").touched
    );
  }

  get validacionRoles() {
    return this.forma.get("roles").invalid && this.forma.get("roles").touched;
  }
  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  agregarRol(roles: string[]) {
    this.clearFormArray(this.rolesItems);
    roles.forEach((element) => {
      this.rolesItems.push(this.fb.control(element));
    });
  }

  get rolesItems() {
    return this.forma.get("roles") as FormArray;
  }

  get linkYTNoValido() {
    return this.forma.get("link").invalid && this.forma.get("link").touched;
  }

  get paisNoValido() {
    return (
      this.forma.get("paisSelected").invalid &&
      this.forma.get("paisSelected").touched
    );
  }
  get destinoNoValido() {
    return (
      this.forma.get("dependenciaDestino").invalid &&
      this.forma.get("dependenciaDestino").touched
    );
  }
  get anioNoValido() {
    return (
      this.forma.get("anioViaje").invalid && this.forma.get("anioViaje").touched
    );
  }

  crearFormulario() {
    this.forma = this.fb.group(
      {
        nombre: ["", [Validators.required]],
        apellido: ["", [Validators.required]],
        roles: this.fb.array([], [Validators.required]),
        telefono: [undefined, [Validators.required]],
        cuil: ["", [Validators.required, Validators.minLength(13)]],
        urlPhoto: [""],
        dependencias: [[], [Validators.required]],
        grupoPertenece: [""],
        link: [""],
        selectedRespuestaPais: [""],
        paisSelected: [""],
        dependenciaDestino: [""],
        anioViaje: [""],
        gradoAcademico: ["", [Validators.required, Validators.maxLength(15)]],
      },
      {
        validators: [
          this.validadores.validateYouTubeUrl(
            "link",
            "selectedRespuestaPais",
            "paisSelected"
          ),
          this.validadores.validatePaisSelected(
            "paisSelected",
            "selectedRespuestaPais"
          ),
          this.validadores.validatePaisSelected(
            "dependenciaDestino",
            "selectedRespuestaPais"
          ),
          this.validadores.validatePaisSelected(
            "anioViaje",
            "selectedRespuestaPais"
          ),
        ],
      }
    );
  }

  guardar() {
    if (this.cargafoto === true || !this.photo) {
      Swal.fire({
        icon: "error",
        title: "Foto no cargada",
        text: "Por favor actualice foto..",
      });
      return;
    }

    this.forma.value.urlPhoto = this.photo;

    if (this.forma.invalid) {
      return Object.values(this.forma.controls).forEach((control) => {
        Swal.fire({
          icon: "error",
          title: "Formulario no válido",
          text: "Por favor corrobore los campos..",
        });
        control.markAsTouched();
      });
    } else {
      this.actualizarGrupo();

      if (Number(this.selectedRespuestaPais) === 1) {
        const viaje: ViajeModel = {
          userId: this.usuario.id,
          userName: `${this.forma.value.nombre} ${this.forma.value.apellido}`,
          codigoPais: this.forma.value.paisSelected.codigo,
          nombrePais: this.forma.value.paisSelected.value,
          dependenciaDestino: this.forma.value.dependenciaDestino,
          anioDeViaje: this.forma.value.anioViaje,
          videoUrl: this.forma.value.link,
        };

        this.viajeService.addViaje(viaje);
      }
      this.auth
        .updateUserProfile(this.forma, this.usuario)
        .then((res) => {
          this.dataSharingService.isUserDeleted.next(false);
          this.verificarRol();

          if (this.rolInvestigador == true) {
            Swal.fire(
              "Perfil cargado",
              "Tiene privilegios para crear grupos  y cargar equipamientos. (menú superior derecho)",
              "success"
            ).then(() => {
              this.router.navigate(["/perfilInvestigador"]);
            });
          } else {
            if (
              this.forma.value.roles.filter((e) => e === "Gestor").length > 0
            ) {
              Swal.fire(
                "Perfil cargado",
                "Tiene privilegios para cargar equipamientos por poseer el rol de Gestor. (menú superior derecho)",
                "success"
              ).then(() => {
                this.router.navigate(["/home"]);
              });
            } else {
              this.router.navigate(["/home"]);
            }
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err.message,
          });
        });
    }
  }

  actualizarPerfil() {
    this.auth.isAuth().subscribe((user) => {
      if (user) {
        user
          .updateProfile({
            displayName: "",
            photoURL: this.inputImageUser.nativeElement.value,
          })
          .then(function () {
            console.log("");
          })
          .catch((error) => console.log("error", error));
      }
    });
  }

  // Validacion Intereses
  seleccionInteres(intereses: string[]) {
    this.clearFormArray(this.interesesItems);
    intereses.forEach((element) => {
      this.interesesItems.push(this.fb.control(element));
    });
  }

  get interesesItems() {
    return this.forma.get("intereses") as FormArray;
  }

  get dependenciasItems() {
    return this.forma.get("dependencias") as FormArray;
  }

  seleccionDependencia(dependencias: string[]) {
    this.clearFormArray(this.dependenciasItems);
    dependencias.forEach((element) => {
      this.dependenciasItems.push(this.fb.control(element));
    });
  }

  public findInvalidControls() {
    const invalid = [];
    const controls = this.forma.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  onSelect(idRespuesta) {
    this.selectedRespuestaPais = Number(idRespuesta);
    if (Number(idRespuesta) === 1) {
      this.paisesSorted = this.paisService.paises.sort((obj1, obj2) =>
        obj1.value.localeCompare(obj2.value)
      );
console.log(this.paisesSorted);
      this.mostrarPaises = true;
    } else {
      this.mostrarPaises = false;
    }
  }

  seleccionPais(pais: Pais) {}

  onUpload() {
    let archivo: string;
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      this.imageCompress
        .compressFile(image, orientation, 40, 50)
        .then((result) => {
          archivo = result;
          console.warn(
            "Size in bytes is now:",
            this.imageCompress.byteCount(result)
          );

          var blob = this.base64ToBlob(archivo, "image/jpeg");
          let reader = new FileReader();
          let urlImagenTemp = reader.readAsDataURL(blob);
          reader.onloadend = () => (this.imagenTemp = reader.result);
          const id = Math.random().toString(36).substring(2);
          const file = blob;
          const filePath = `uploads/profile_${id}`;
          const ref = this.storage.ref(filePath);
          const task = this.storage.upload(filePath, file);
          this.uploadPercent = task.percentageChanges();

          task
            .snapshotChanges()
            .pipe(finalize(() => (this.urlImage = ref.getDownloadURL())))
            .subscribe();
          this.cargafoto = true;
        });
    });
  }

  actualizarImg() {
    this.photo = this.inputImageUser.nativeElement.value;

    this.cargafoto = false;
  }

  seleccionGrupo(e) {
    if (e) {
      this.grupoElegido = e;
    }
  }

  agregarMiembro(idGRUPO) {
    let groups: GrupoModel[] = [];

    groups = this.groups.filter((item) => item.id == idGRUPO);

    this.grupo = groups[0];
    let rolUsuario: string;

    if (this.usuario.rol.length > 1) {
      rolUsuario = "Docente-Investigador";
    } else {
      rolUsuario = this.usuario.rol[0];
    }
    const user: integrante = {
      user: this.usuario.id,
      rol: rolUsuario,
    };
    const id = this.usuario.id;
    if (this.grupo) {
      if (this.grupo.integrantes.length >= 0) {
        if (
          this.grupo.integrantes.filter(function (e) {
            return e.user === id;
          }).length > 0
        ) {
          /* vendors contains the element we're looking for */
        } else {
          this.grupo.integrantes.push(user);
        }
      }
    }
  }

  eliminarMiembro() {
    if (this.grupoPertenece) {
      const idMiembro = this.usuario.id;
      const groups = this.groups.filter(
        (item) => item.id == this.grupoPertenece
      );
      this.grupo = groups[0];
      if (this.grupo) {
        this.grupo.integrantes.forEach((item, index) => {
          if (item.user === idMiembro) {
            this.grupo.integrantes.splice(index, 1);
          }
        });
        this.grupoService.updateGroup(this.grupo);
      }
    }
  }

  actualizarGrupo() {
    if (this.grupoElegido != "0: undefined") {
      this.usuario.grupoPertenece = this.grupoElegido;
      if (
        this.grupoElegido !== this.grupoPertenece &&
        this.grupoPertenece != "0: undefined" &&
        this.grupoElegido
      ) {
        this.eliminarMiembro();

        this.grupoService.updateGroup(this.grupo);

        this.usuario.grupoPertenece = this.grupoElegido;
        this.forma.value.grupoPertenece = this.usuario.grupoPertenece;
        this.agregarMiembro(this.usuario.grupoPertenece);

        this.grupoService.updateGroup(this.grupo);
      } else {
        if (!this.grupoElegido && this.grupoPertenece) {
          this.forma.value.grupoPertenece = this.grupoPertenece;
        } else {
          this.forma.value.grupoPertenece = this.grupoElegido;
          this.agregarMiembro(this.grupoElegido);

          this.grupoService.updateGroup(this.grupo);
        }
      }
    } else {
      this.eliminarMiembro();
      this.usuario.grupoPertenece = "";
      this.forma.value.grupoPertenece = "";
    }
  }

  verificarRol() {
    if (this.usuario.rol) {
      if (
        this.usuario.rol.indexOf("Investigador") >= 0 ||
        this.usuario.rol.indexOf("Docente-Investigador") >= 0 ||
        this.usuario.rol[0].includes("Docente")
      ) {
        this.rolInvestigador = true;
      } else {
        this.rolInvestigador = false;
      }
      if (this.usuario.rol[1]) {
        if (this.usuario.rol[1].includes("Docente")) {
          this.rolInvestigador = true;
        }
      }
    }
  }

  // Validacion telefono
  changePreferredCountries() {
    this.preferredCountries = [CountryISO.Argentina, CountryISO.Canada];
  }

  eliminar() {
    Swal.fire({
      title: "¿Esta seguro que desea eliminar su perfil?",
      text: "El cambio es irreversible",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.value) {
        this.auth.borrarCuenta(this.usuario).finally(() => {
          this.dataSharingService.isUserDeleted.next(true);
          Swal.fire(
            "Eliminado",
            "El perfil ha sido eliminado.",
            "success"
          ).then(() => {
            this.reloadComponent();
          });
        });
      }
    });
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigate(["/home"]);
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  redirectTo(uri) {
    this.router
      .navigateByUrl("/", { skipLocationChange: true })
      .then(() => this.router.navigate([uri]));
  }

  ver(e) {
  }
  verup(eUp) {
    this.forma.get("cuil").setValue(eUp);
  }

  public base64ToBlob(b64Data, contentType = "", sliceSize = 512) {
    b64Data = b64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, ""); //IE compatibility...
    let byteCharacters = atob(b64Data);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
