import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { GestionLinksDeInteresComponent } from './components/pages/gestionLinksDeInteres/GestionLinksDeInteres.component';
import { CreateOrEditLinkComponent } from './components/pages/createOrEditLink/CreateOrEditLink.component';
import { ReportsComponent } from './components/pages/reports/Reports.component';
import { ReportsByGroupComponent } from './components/pages/reports-by-group/reports-by-group.component';
import { ReportsByUsersComponent } from './components/pages/reports-by-users/reports-by-users.component';



import { ContactComponent } from './components/pages/contact/contact.component';
import { EquipamientoComponent } from './components/Equipos/equipamiento/equipamiento.component';
import { ServiceComponent } from './components/pages/movilizados/movilizados.component';
import { TeamComponent } from './components/grupos/team/team.component';
import { InvestigadoresComponent } from './components/investigadores/investigadores/investigadores.component';
import { RegistroComponent } from './components/User/registro/registro.component';
import { LoginComponent } from './components/User/login/login.component';
import { InvestigadorComponent } from './components/investigadores/investigador/investigador.component';
import { BuscadorInvestigadoresComponent } from './components/investigadores/buscador-investigadores/buscador-investigadores.component';
import { VistaGruposComponent } from './components/grupos/vista-grupos/vista-grupos.component';
import { IntegrantesGrupoComponent } from './components/grupos/integrantes-grupo/integrantes-grupo.component';
import { BuscadorGruposComponent } from './components/grupos/buscador-grupos/buscador-grupos.component';
import { VinculosComponent } from './components/pages/vinculos/vinculos.component';
import { CatalogoComponent } from './components/pages/catalogo/catalogo.component';
import { PerfilComponent } from './components/User/perfil/perfil.component';
import { AdministradorComponent } from './components/grupos/administrador/administrador.component';
import { CrearGrupoComponent } from './components/grupos/crear-grupo/crear-grupo.component';
import { PerfilInvestigadorComponent } from './components/User/perfil-investigador/perfil-investigador.component';
import { CargarEquipoComponent } from './components/Equipos/cargar-equipo/cargar-equipo.component';
import { VistaEquipoComponent } from './components/Equipos/vista-equipo/vista-equipo.component';
import { AppComponent } from './app.component';
import { SendVerificationComponent } from './components/User/send-verification/send-verification.component';
import { ForgotPasswordComponent } from './components/User/forgot-password/forgot-password.component';
import {GruposInvestigacionComponent} from './components/grupos/grupos-investigacion/grupos-investigacion.component';
import {UsuariosComponent} from './components/User/usuarios/usuarios.component';
import {UsuarioComponent} from './components/User/usuario/usuario.component';
import {RolesComponent} from './components/roles/roles.component';
import {RolComponent} from './components/rol/rol.component';
import {ReporteUsuariosComponent} from './components/pages/reports/reporte-usuarios/reporte-usuarios.component';
import {ReporteOfertasTecnoComponent} from './components/pages/reports/reporte-ofertas-tecno/reporte-ofertas-tecno.component';
import {ReporteEquipamientoComponent} from './components/pages/reports/reporte-equipamiento/reporte-equipamiento.component';
import {ReporteGruposComponent} from './components/pages/reports/reporte-grupos/reporte-grupos.component';
import {ReporteViajesComponent} from './components/pages/reports/reporte-viajes/reporte-viajes.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'gestionLinksDeInteres', component: GestionLinksDeInteresComponent},
  { path: 'createOrEditLink', component: CreateOrEditLinkComponent},
  { path: 'reportes', component: ReportsComponent},
  { path: 'createOrEditLink/:id', component: CreateOrEditLinkComponent},
  { path: 'reportsByGroup', component: ReportsByGroupComponent},
  { path: 'reportsByUsers', component: ReportsByUsersComponent},


  { path: 'investigadores', component: InvestigadoresComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'equipos', component: EquipamientoComponent },
  { path: 'movilizados', component: ServiceComponent },
  { path: 'grupos', component: TeamComponent },
  { path: 'registro', component: RegistroComponent },
  { path: 'login', component: LoginComponent },
  { path: 'verificacion', component: SendVerificationComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'buscarInvestigador/:termino', component: BuscadorInvestigadoresComponent },
  { path: 'buscarGrupos/:termino', component: BuscadorGruposComponent },
  { path: 'vistaGrupos/:termino', component: VistaGruposComponent },
  { path: 'vistaIntegrantes/:termino', component: IntegrantesGrupoComponent },
  { path: 'investigador/:id', component: InvestigadorComponent },
  { path: 'vinculos', component: VinculosComponent },
  { path: 'app', component: AppComponent },
  { path: 'catalogo', component: CatalogoComponent },
  { path: 'perfil', component: PerfilComponent },
  { path: 'perfilInvestigador', component: PerfilInvestigadorComponent },
  { path: 'creargrupo/:id', component: CrearGrupoComponent },
  { path: 'creargrupo', redirectTo: 'creargrupo/', pathMatch: 'full' },
  { path: 'cargaEquipo/:id', component: CargarEquipoComponent },
  { path: 'cargaEquipo', redirectTo: 'cargaEquipo/', pathMatch: 'full' },
  { path: 'reserva/:id', component: VistaEquipoComponent },
  { path: 'ofertaTecnologica', loadChildren: () => import('./modules/tech-offers/tech-offers.module').then(m => m.TechOffersModule) },
  { path: 'roles', component: RolesComponent },
  { path: 'rol', component: RolComponent },
  { path: 'rol/:id', component: RolComponent },
  { path: 'grupos-investigacion', component: GruposInvestigacionComponent },
  { path: 'usuarios', component: UsuariosComponent },
  { path: 'usuario/:id', component: UsuarioComponent },
  { path: 'reporte-usuarios', component: ReporteUsuariosComponent },
  { path: 'reporte-ofertas-tecno', component: ReporteOfertasTecnoComponent },
  { path: 'reporte-equipamiento', component: ReporteEquipamientoComponent },
  { path: 'reporte-grupos', component: ReporteGruposComponent },
  { path: 'reporte-viajes', component: ReporteViajesComponent },

  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }




