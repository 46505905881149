import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { EquipoModel } from "src/app/models/equipo.model";
import { Observable } from "rxjs/internal/Observable";
import { EquipamientoService } from "src/app/service/equipamiento.service";
import { Convenio } from "src/app/models/grupo.model";
import { MatPaginator } from "@angular/material/paginator";
import { UsuarioModel } from "src/app/models/usuario.model";
import { AuthService } from "src/app/service/auth.service";

export interface linksTable {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: "app-tabla-equipos",
  templateUrl: "./tabla-equipos.component.html",
  styleUrls: ["./tabla-equipos.component.css"],
})
export class TablaEquiposComponent implements OnInit {
  ELEMENT_DATA: linksTable[] = [];
  equipos: Observable<EquipoModel[]>;
  displayedColumns: string[] = [
    "nombre",
    "marca",
    "dependencia",
    "servicios",
    "accion",
  ];
  dataSource;
  usuario: UsuarioModel = new UsuarioModel();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private equipoService: EquipamientoService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    // Obtiene el usuario
    this.auth.isAuth().subscribe((user) => {
      if (user) {
        this.usuario = user;
        this.usuario.id = user.uid;
      }
    });
    this.equipoService.getAllEquipos().subscribe((list) => {
      let array = list.map((item) => {
        return {
          ...item,
        };
      });
      this.dataSource = new MatTableDataSource(array);
      this.dataSource.paginator = this.paginator;
      this.paginator._intl.itemsPerPageLabel = "Items por página";
      this.paginator._intl.getRangeLabel = (
        page: number,
        pageSize: number,
        length: number
      ) => {
        if (length == 0 || pageSize == 0) {
          return `0 de ${length}`;
        }

        length = Math.max(length, 0);

        const startIndex = page * pageSize;

        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex =
          startIndex < length
            ? Math.min(startIndex + pageSize, length)
            : startIndex + pageSize;

        return `${startIndex + 1} - ${endIndex} de ${length}`;
      };
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}

export interface linksTable {
  id?: string;
  codigo?: string;
  equipamiento?: string;
  // responsableId ?: string;
  dependencia?: string;
}
