import { Component, OnInit } from '@angular/core';
import { EquipoModel } from 'src/app/models/equipo.model';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ContactoService } from 'src/app/service/contacto.service';
import Swal from 'sweetalert2';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  providers: [ContactoService]
})
export class ContactComponent implements OnInit {

  equipo: EquipoModel= new EquipoModel();
  forma: FormGroup;
  constructor(public contactoService: ContactoService, private activatedRoute: ActivatedRoute,
              private datePipe: DatePipe, private http: HttpClient, private router: Router) {


   }

   mailUrl = "https://us-central1-vinculacioncientifica-fc666.cloudfunctions.net/sendMail";
  
  ngOnInit() {


}
onClear() {
  this.contactoService.form.reset();
  this.contactoService.initializeFormGroup();

}
onSubmit(){
  
  if (this.contactoService.form.invalid) {
      
    return Object.values(this.contactoService.form.controls).forEach( control => {
      control.markAsTouched();
    });
  } else {
    this.sendMessage().subscribe();
    this.router.navigate(['/home']);
      Swal.fire(
        'Enviado',
        'Mensaje eniviado con éxito.',
        'success'
      );
    

  }
}

private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // TODO: send the error to remote logging infrastructure
    console.error(error); // log to console instead
    // TODO: better job of transforming error for user consumption
    console.log(`${operation} failed: ${error.message}`);
    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

sendMessage(): Observable<any> {
  
  // console.log('this.reservaService.form.value :>> ', this.reservaService.form.value);

  return this.http.post<any>(this.mailUrl ,this.contactoService.form.value, httpOptions).pipe(
    tap(
      message => console.log(message)
    ),
    catchError(this.handleError('Sending Message', []))
  );

  // this.http.post(this.mailUrl,this.reservaService.form.value, httpOptions).toPromise()
  // .then( res => {
  //   this.router.navigate(['/equipos']);
      Swal.fire(
        'Enviado',
        'Solicitud enivada con éxito.',
        'success'
      );
  // })
  // .catch(err => {
  //   console.log(err)
  // });

  // this.http.post(this.mailUrl,this.reservaService.form.value, httpOptions).subscribe();
}

}
