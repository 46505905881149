import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-equipamiento',
  templateUrl: './equipamiento.component.html',
  styleUrls: ['./equipamiento.component.css']
})
export class EquipamientoComponent implements OnInit {

  public activo: boolean = false;
  constructor() { }

  ngOnInit() {
   
  }


  aparecerSeccion(){
    this.activo= !this.activo;
  }
}
