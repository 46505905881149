import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from "@angular/fire/firestore";
import {UsuarioModel} from "../models/usuario.model";
import {LinkModel} from "../models/link.model";

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  private usuarioCollection: AngularFirestoreCollection<UsuarioModel>;

  constructor(private afs: AngularFirestore) { }

  getUsuarios() {
    return this.afs
      .collection("usuarios")
      .valueChanges({ idField: "id" });
  }

  getUsuario(usuarioId: string) {
    return this.afs.collection("usuarios").doc(usuarioId).valueChanges();
  }

  updateUsuario(usuario: UsuarioModel, usuarioId): Promise<any> {
    return this.afs.collection("usuarios").doc(usuarioId).update(usuario);
  }

  addUsuario(usuario: UsuarioModel): Promise<any> {
    return this.afs.collection("usuarios").add(usuario);
  }

  // deleteUsuarioById(usuario: UsuarioModel) {
  //   this.usuarioCollection = this.afs.collection<UsuarioModel>("usuarios");
  //   return this.usuarioCollection.doc(usuario.id).delete();
  // }

  // Implementado para remover las letras con tilde y los espacios vacios en los extremos de un termino de búsqueda
  removeAccentsAndSpaces(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim();
  }
}
