import { Component, OnInit } from '@angular/core';
import {map, takeUntil} from 'rxjs/operators';
import {AuthService} from '../../../../service/auth.service';
import {ExporterService} from '../../../../service/exporter.service';
import {Subject} from 'rxjs';
import { GruposService} from '../../../../service/grupos.service';

interface Usuario {
  Nombre?: string;
  Apellido?: string;
  CUIL?: string;
  email?: string;
  Roles?: string;
  Dependencia?: string;
  Telefono?: string;
  Grado_Academico?: string;
  Disciplina?: string;
  cvar?: string;
  Cant_Proyectos?: number;
  Proyectos?: string;
  Intereses?: string;
  LinkedIn?: string;
  ResearchGate?: string;
  Grupo?: string;
}

@Component({
  selector: 'app-reporte-usuarios',
  templateUrl: './reporte-usuarios.component.html',
  styleUrls: ['./reporte-usuarios.component.css']
})
export class ReporteUsuariosComponent implements OnInit {
  unsubscribe$ = new Subject();
  dataSourceUsuarios: Usuario[] = [];
  displayedColumns: string[] = ['Nombre', 'Apellido', 'CUIL', 'email', 'Dependencia', 'Telefono'];

  constructor(private authService: AuthService,
              private excelService: ExporterService,
              private gruposService: GruposService) { }

  ngOnInit() {
    this.cargarReporte();
  }

  cargarReporte() {
    this.authService.getAllUsers()
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(
        map((usuarios: any) => {
          if (usuarios) {

            for (const usuario of usuarios) {
              let rolesTemp = '';
              if (usuario.rol) {
                for (const rol of usuario.rol) {
                  rolesTemp = rolesTemp.concat(`${rol}, `);
                }
                rolesTemp = rolesTemp.slice(0, -2);
              }

              let dependenciasTemp = '';
              if (usuario.dependencias) {
                for (const dependencia of usuario.dependencias) {
                  dependenciasTemp = dependenciasTemp.concat(`${dependencia}, `);
                }
                dependenciasTemp = dependenciasTemp.slice(0, -2);
              }

              let interesesTemp = '';
              if (usuario.intereses) {
                for (const interes of usuario.intereses) {
                  interesesTemp = interesesTemp.concat(`${interes}, `);
                }
                interesesTemp = interesesTemp.slice(0, -2);
              }

              let proyectosTemp = '';
              let cantProyectos = 0;
              if (usuario.tipoDeProyectos) {
                for (const proyecto of usuario.tipoDeProyectos) {
                  proyectosTemp = proyectosTemp.concat(`${proyecto}, `);
                  cantProyectos = cantProyectos + 1;
                }
                proyectosTemp = proyectosTemp.slice(0, -2);
              }

              if (usuario.grupoPertenece) {
                this.gruposService.getGroupById(usuario.grupoPertenece).subscribe((grupos: any) => {
                    if (grupos) {
                      usuario.Grupo = grupos.nombre;
                    }
                  }
                );
              }


              usuario.Nombre = usuario.nombre;
              usuario.Apellido = usuario.apellido;
              usuario.CUIL = usuario.cuil;
              usuario.LinkedIn = usuario.linkedin;
              usuario.ResearchGate = usuario.researchGate;
              usuario.Roles = rolesTemp;
              usuario.Dependencia = dependenciasTemp;
              usuario.Intereses = interesesTemp;
              usuario.Proyectos = proyectosTemp;
              usuario.Cant_Proyectos = cantProyectos;
              usuario.Telefono = usuario.telefono ? usuario.telefono.number : '';
              usuario.Grado_Academico = usuario.gradoAcademico;
              usuario.Disciplina = usuario.disciplina;

              delete usuario.apellido;
              delete usuario.cuil;
              delete usuario.dependencias;
              delete usuario.descripcion;
              delete usuario.disciplina;
              delete usuario.gradoAcademico;
              delete usuario.grupoPertenece;
              delete usuario.id;
              delete usuario.intereses;
              delete usuario.linkedin;
              delete usuario.micrositio;
              delete usuario.nombre;
              delete usuario.participacionProyecto;
              delete usuario.photoUrl;
              delete usuario.researchGate;
              delete usuario.rol;
              delete usuario.sitioPropio;
              delete usuario.telefono;
              delete usuario.tipoDeProyectos;
              delete usuario.enable;
              delete usuario.tipoDeProyecto;

            }
          }
          return usuarios;
        })
      )
      .subscribe((values: any) => {
        this.dataSourceUsuarios = Object.values(values);
      });
  }

  generarReporte() {
    // console.log(this.dataSourceUsuarios);
    this.excelService.exportToExcel(this.dataSourceUsuarios, 'detalle_usuarios_db');
  }
}
