import { Injectable } from '@angular/core';
import { FormArray, FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidadoresService {

  constructor() { }

  // if (control.value.includes('Estudiante de pre-grado') && control.value.some('Estudiante de grado', 'Estudiante de posgrado','Egresado',  'Docente', 'Investigador', 'Gestor')
  // || control.value.includes('Gestor') && control.value.includes('Docente')
  // || control.value.includes('Gestor') && control.value.includes('Gestor')
  // || control.value.includes('Docente') && control.value.includes('Gestor')
  // || control.value.includes('Investigador') && control.value.includes('Gestor'))


  rolesIncompatibles(control: FormArray): { [s: string]: boolean } {


    if (control.length > 1) {
      if (((control.value.includes('Investigador')) && (control.value[0].includes('Docente') || control.value[1].includes('Docente'))) && control.length < 3) {
        // return{

        //   rolesIncompatibles: false
        // };
      } else {
        return {
          rolesIncompatibles: true
        };
      }


    }

    return null;


  }

  excesoConvocatorias(control: FormArray): { [s: string]: boolean } {


    if (control.length > 2) {

      return {
        convocatoriasExcedidas: true
      };



    }

    return null;


  }



  passwordsIguales(pass1Name: string, pass2Name: string) {

    return (formGroup: FormGroup) => {
      const pass1Control = formGroup.controls[pass1Name];
      const pass2Control = formGroup.controls[pass2Name];

      if (pass1Control.value === pass2Control.value) {
        pass2Control.setErrors(null);
      } else {
        pass2Control.setErrors({ noEsIgual: true });
      }
    }


  }


  validateYouTubeUrl(url, resp, paisSelected) {




    return (formGroup: FormGroup) => {
      const linkControl = formGroup.controls[url];
      if (formGroup.controls[resp].value == 1) {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
        const match = String(linkControl.value).match(regExp);
        if (match && match[2].length == 11) {
          linkControl.setErrors(null);
          // Do anything for being valid
          // if need to change the url to embed url then use below line
          // $('#videoObject').attr('src', 'https://www.youtube.com/embed/' + match[2] + '?autoplay=1&enablejsapi=1');
        } else {
          linkControl.setErrors({ formatoIncompatiblr: true });
          // alert('not valid');
          // Do anything for not being valid
        }
      }
      else {
        linkControl.setErrors(null);
        // alert('not valid');
        // Do anything for not being valid
      }

    }






  }


  validatePaisSelected(paisSelected, resp) {




    return (formGroup: FormGroup) => {

      const paisControl = formGroup.controls[paisSelected];

      if (formGroup.controls[resp].value == 1) {

        if (paisControl.value) {
          paisControl.setErrors(null);

        } else {
          paisControl.setErrors({ paisNoElegido: true });
          // alert('not valid');
          // Do anything for not being valid
        }
      } else {
        paisControl.setErrors(null);
        // alert('not valid');
        // Do anything for not being valid
      }

    }
  }

  validateDisciplina(disciplinaSelected) {
    return (formGroup: FormGroup) => {
      const disciplinaControl = formGroup.controls[disciplinaSelected];
      if (formGroup.controls[disciplinaSelected].value) {
        disciplinaControl.setErrors(null);
      } else {
        disciplinaControl.setErrors({ invalido: true });
      }
    }
  }

  minLengthArray(min: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length == min)
        return null;

      return { 'minLengthArray': { valid: false } };
    }
  }


  // minSelectedCheckboxes(): ValidatorFn {
  //   const validator: ValidatorFn = (formArray: FormArray) => {

  //      const selectedCount = formArray.controls
  //         .map(control => control.value)
  //         .reduce((prev, next) => next ? prev + next : prev, 0);

  //      return selectedCount >= 1 ? null : { notSelected: true };
  //   };

  //   return validator;
  // }
}
