import { Injectable } from "@angular/core";
import { GrupoModel } from "../models/grupo.model";
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from "@angular/fire/firestore";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";

@Injectable()
export class GruposService {
  grupoDoc: AngularFirestoreCollection<GrupoModel>;
  private groupCollection: AngularFirestoreCollection<GrupoModel>;
  private grupo: Observable<GrupoModel[]>;
  private grupoI: Observable<GrupoModel>;
  private grupos;
  private groups: Observable<GrupoModel[]>;
  private docGrupo: AngularFirestoreDocument<GrupoModel>;

  constructor(
    private afs: AngularFirestore
  ) { }

  getAllDependencies() {
    return this.afs.collection('dependencias').valueChanges();
  }

  getUserByEmail(email: string) {
    return this.afs.collection('usuarios', ref => ref.where('email', '==', email)).valueChanges({ idField: 'id' });
  }

  getGroupById(groupId: string) {
    return this.afs.collection('grupos').doc(groupId).valueChanges();
  }

  buscarGruposPorDisciplina(termino: string): Grupo[] {
    let gruposArr: Grupo[] = [];

    termino = termino.toLowerCase();
    for (const grupo of this.grupos) {
      const disciplina = grupo.disciplina.toLowerCase();
      if (disciplina.indexOf(termino) >= 0) {
        gruposArr.push(grupo);
      }
    }
    return gruposArr;
  }

  getGrupoFirebaseDisciplina(disciplina: string) {
    this.grupoDoc = this.afs.collection<GrupoModel>("grupos", (ref) =>
      ref.where(
        "disciplina",
        "==",
        disciplina.charAt(0).toUpperCase() + disciplina.slice(1).toLowerCase()
      )
    );
    return (this.grupo = this.grupoDoc.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as Grupo;
          data.id = action.payload.doc.id;
          (data.nombre = action.payload.doc.data().nombre),
            (data.bio = action.payload.doc.data().descripcion),
            (data.img = action.payload.doc.data().photoUrl),
            (data.disciplina = action.payload.doc.data().disciplina);
          data.unidadAcademica = action.payload.doc.data().dependencias;

          return data;
        });
      })
    ));
  }

  buscarGrupos(termino: string): GrupoModel[] {
    termino = termino.toLowerCase();

    const gruposArr: GrupoModel[] = [];

    this.getAllGroups().subscribe((data) => {
      data.forEach((element) => {
          const nombre = element.nombre ? element.nombre.toLowerCase() : [];
          const disciplina = element.disciplina ? element.disciplina.toLowerCase() : [];

          if (element.dependencias) {
            element.dependencias.forEach((dep) => {
              if (
                dep.toLowerCase().indexOf(termino) >= 0 &&
                gruposArr.indexOf(element) < 0
              ) {
                gruposArr.push(element);
              }
            });
          }

          if (
            (nombre.indexOf(termino) >= 0 ||
              disciplina.indexOf(termino) >= 0) &&
            gruposArr.indexOf(element) < 0
          ) {
            gruposArr.push(element);
          }
      });
    });
    return gruposArr;
  }

  getGrupoResponsable(idResponsable: string) {
    return this.afs.collection('grupos', ref => ref.where('idResponsable', '==', idResponsable)).valueChanges({ idField: 'id' });
  }

  getGrupoFirebaseEncargado(idEncargado: string) {
    this.grupoDoc = this.afs.collection<GrupoModel>("grupos", (ref) =>
      ref.where("idResponsable", "==", idEncargado)
    );
    return (this.grupo = this.grupoDoc.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as GrupoModel;
          data.id = action.payload.doc.id;
          (data.nombre = action.payload.doc.data().nombre),
            (data.descripcion = action.payload.doc.data().descripcion),
            (data.photoUrl = action.payload.doc.data().photoUrl),
            (data.disciplina = action.payload.doc.data().disciplina);
          data.dependencias = action.payload.doc.data().dependencias;
          data.idResponsable = action.payload.doc.data().idResponsable;
          return data;
        });
      })
    ));
  }

  getGrupoFirebase(idx: string) {
    this.docGrupo = this.afs.doc<GrupoModel>(`grupos/${idx}`);
    return (this.grupoI = this.docGrupo.snapshotChanges().pipe(
      map((action) => {
        if (action.payload.exists == false) {
          return null;
        } else {
          const data = action.payload.data() as GrupoModel;
          (data.id = action.payload.data().id),
            (data.nombre = action.payload.data().nombre),
            (data.descripcion = action.payload.data().descripcion),
            (data.photoUrl = action.payload.data().photoUrl),
            (data.disciplina = action.payload.data().disciplina);
          data.dependencias = action.payload.data().dependencias;
          data.idResponsable = action.payload.data().idResponsable;
          return data;
        }
      })
    ));
  }

  getDisciplinas() {
    return [
      "Agronomía",
      "Antropología, sociología y ciencias políticas",
      "Arquitectura",
      "Artes",
      "Biología",
      "Ciencias de la tierra, el mar y la atmósfera",
      "Derecho y jurisprudencia",
      "Economía, administración y contabilidad",
      "Educación",
      "Filosofía",
      "Física",
      "Historia y geografía",
      "Ingeniería",
      "Literatura y lingüística",
      "Matemática",
      "Medicina, odontología y ciencias de la salud",
      "Psicología",
      "Química, bioquímica y farmacia",
      "Veterinaria",
    ];
  }

  addGroup(group: GrupoModel): void {
    console.log("group :>> ", group);
    this.groupCollection = this.afs.collection("grupos");
    this.groupCollection.add(group);
  }

  getAllGroups() {
    this.groupCollection = this.afs.collection<GrupoModel>("grupos");
    return (this.groups = this.groupCollection.snapshotChanges().pipe(
      map((changes) => {
        return changes.map((action) => {
          const data = action.payload.doc.data() as GrupoModel;
          data.id = action.payload.doc.id;
          return data;
        });
      })
    ));
  }

  updateGroup(grupo: GrupoModel): void {
    let idGrupo = grupo.id;
    this.docGrupo = this.afs.doc<GrupoModel>(`grupos/${idGrupo}`);
    this.docGrupo.update(grupo);
  }

  deleteGrupoById(id: string) {
    this.groupCollection = this.afs.collection("grupos");
    return this.groupCollection.doc(id).delete();
  }

  getGruposInvestigacion() {
    return this.afs
      .collection("grupos")
      .valueChanges({ idField: "id" });
  }

}

export interface Grupo {
  id: string;
  nombre: string;
  bio: string;
  img: string;
  disciplina: string;
  unidadAcademica: string[];
}
