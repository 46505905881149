import { Component, OnInit } from "@angular/core";
import { GruposService } from "src/app/service/grupos.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ExporterService } from '../../../service/exporter.service';
import { AuthService } from "src/app/service/auth.service";


export interface ReportsByUsersTable {
  usersPerGroup: number;
  groups: string;
}

@Component({
  selector: "app-reports-by-users",
  templateUrl: "./reports-by-users.component.html",
  styleUrls: ["./reports-by-users.component.css"],
})
export class ReportsByUsersComponent implements OnInit {
  unsubscribe$ = new Subject();
  displayedColumns: string[] = ["dependency", "usuarios"];
  dataSource = [];

  constructor(
    private authService: AuthService,
    private excelService: ExporterService
  ) { }

  ngOnInit() {
    this.tableData();
  }

  tableData() {
    this.authService.getAllUsers()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((values) => {
        if (values) {
          const depSet = new Set();
          values.forEach((user: any) => {
            if (user.dependencias) {
              user.dependencias.forEach(dep => {
                depSet.add(dep);
              });
            }
          });
          const report = [];

          Array.from(depSet).forEach((dependency: string, index: number) => {
            report.push({ dependency: dependency, usuarios: 0 })
            values.forEach((user: any) => {
              if (user.dependencias) {
                if (user.dependencias.find(dep => dep === dependency)) {
                  report[index].usuarios++;
                }
              }
            })
          });

          this.dataSource = report;
        }
      });
  }

  exportAsXLSX(): void {
    this.excelService.exportToExcel(this.dataSource, 'usuarios_por_dependencia')
  }
}
