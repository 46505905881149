import { Component, OnInit } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { InvestigadorService, Investigador } from 'src/app/service/investigadores.service';
import { Observable } from 'rxjs/internal/Observable';
import {Location} from '@angular/common';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { GruposService } from 'src/app/service/grupos.service';


@Component({
  selector: 'app-investigador',
  templateUrl: './investigador.component.html',
  styleUrls: ['./investigador.component.css', 'investigador.component.scss']
})
export class InvestigadorComponent implements OnInit {
  
  investigador:UsuarioModel = {
    nombre: '',
    
    photoUrl:'',
    disciplina:''
  };
  nombreGrupo: string = "";

  constructor( private activatedRoute: ActivatedRoute, private _location: Location,
               private _investigadorService: InvestigadorService, private _grupoService: GruposService) {

     
     }

  ngOnInit() {
    this.activatedRoute.params.subscribe( params => {
      
      this._investigadorService.getInvestigadorFirebase(params['id']).subscribe(investigador => {
        if (investigador) {
          
       

          this.investigador = investigador;
          // console.log('this.investigador :>> ', this.investigador);
          // if (this.usuario.rol.indexOf('Investigador') >= 0 && (this.usuario.rol[0].includes('Docente') )) {
          //   this.usuario.rol[0] = 'Docente - Investigador'
          // } 

          if (this.investigador.grupoPertenece) {
              const id = this.investigador.grupoPertenece;

              this._grupoService.getGrupoFirebase(id).subscribe(data => {
                if (data) {
                  this.nombreGrupo = data.nombre;
                }
                
              });
          }

          if (investigador.rol.indexOf('Investigador') >= 0 && 
          (investigador.rol.indexOf('Docente de pre-grado') >= 0 || investigador.rol.indexOf('Docente de grado') >= 0 || investigador.rol.indexOf('Docente de posgrado') >= 0
          || investigador.rol.indexOf('Docente-Investigador') >= 0 ) ) {
          this.investigador.rol[0] = 'Docente - Investigador';
        } else if(investigador.rol.indexOf('Investigador') >= 0){
          this.investigador.rol[0] = 'Investigador';
        } else if(investigador.rol.indexOf('Docente - Investigador') >= 0){
          this.investigador.rol[0] = 'Docente - Investigador';
        } 

        //   if (investigador.rol.indexOf('Investigador') >= 0) {
        //   this.investigador.rol[0] = 'Investigador';
        // }
        
        //   if (investigador.rol.indexOf('Docente-Investigador') >= 0) {
        //   this.investigador.rol[0] = 'Docente-Investigador';
        // }
      

        }
      });
      
    });
  }


  backClicked() {
    this._location.back();
  }

  
}
