import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GruposService } from 'src/app/service/grupos.service';
import { Observable } from 'rxjs/internal/Observable';
import { GrupoModel, integrante } from 'src/app/models/grupo.model';
import { UsuarioModel } from 'src/app/models/usuario.model';
import { InvestigadorService } from 'src/app/service/investigadores.service';
import { trigger, style, animate, transition } from '@angular/animations';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-integrantes-grupo',
  templateUrl: './integrantes-grupo.component.html',
  styleUrls: ['./integrantes-grupo.component.css'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]
    )
  ]
})
export class IntegrantesGrupoComponent implements OnInit {
  show: boolean = false;
  termino: string;
  grupo: GrupoModel;
  rolesGrupo: any[] = [];
  integrantes: UsuarioModel[] = []
  integrantesRol: UsuarioModel[] = []
  rol: string;
  respId: string;
  tempRol: string;
  usuario: UsuarioModel = new UsuarioModel();
  administrar = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private grupoService: GruposService,
    private investigadorService: InvestigadorService,
    private auth: AuthService
  ) { }

  ngOnInit() {
    // Traigo al usuario
    this.auth.isAuth().subscribe(user => {
      if (user) {
        this.usuario = user;
        this.usuario.id = user.uid;
      }
    });

    this.activatedRoute.params.subscribe(params => {
      this.termino = params['termino'];

      this.grupoService.getGrupoFirebase(this.termino).subscribe(data => {
        if (data) {
          this.grupo = data;
          this.respId = this.grupo.idResponsable;
          if (this.usuario.id === this.respId) {
            this.administrar = true;
          }
          if (this.grupo.integrantes) {
            let i = 2;
            this.grupo.integrantes.forEach(element => {
              i++;
              this.investigadorService.getInvestigadorFirebase(element.user).subscribe(usuario => {

                if (usuario) {

                  element.rol = usuario.rol[0];
                  let usr: any = {
                    ...usuario,
                    rol: element.rol,
                    index: i,
                    show: false
                  }
                  if (usuario.idx == this.respId) {
                    usr.rol = 'Responsable';
                    element.rol = 'Responsable'
                  }
                  if (usuario.rol.length > 1 && usuario.idx != this.respId) {
                    usr.rol = 'Docente-Investigador';
                    element.rol = 'Docente-Investigador'
                  }

                  this.integrantes.push(usr);
                  if (this.integrantes.filter((item) => item.rol == usr.rol).length <= 1) {
                    if (usr.rol === "Responsable") {
                      usr.index = 1;
                    }

                    this.rolesGrupo.push(usr);
                    if (usr.rol === "Responsable") {
                      this.rolClick(usr);
                    }
                  }
                  this.rolesGrupo.sort((a, b) => (a.index < b.index ? -1 : 1))
                }
              })
            });
          }
        }
      })
    })
  }

  rolClick(e) {
    if (e) {
      const indice = this.rolesGrupo.map(function (e) { return e.rol; }).indexOf(e.rol);
      this.rolesGrupo[indice].show = true;
      this.rolesGrupo.forEach(element => {
        if (this.rolesGrupo.indexOf(element) != indice) {
          element.show = false;
        }
      });
      this.rol = e.rol;
      this.integrantesRol = this.integrantes.filter((item) => item.rol == e.rol);
    }

  }

  administrarGrupo() {
    this.router.navigate(['/creargrupo', this.termino]);
  }

}
