import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';
import { LinksService } from 'src/app/service/links.service';

@Component({
  selector: 'app-vinculos',
  templateUrl: './vinculos.component.html',
  styleUrls: ['./vinculos.component.css']
})
export class VinculosComponent implements OnInit {

  links = [];

  constructor(
    private linksService: LinksService
  ) { }

  ngOnInit() {
    this.linksService.getAllLinks()
      .pipe(
        take(1)
      )
      .subscribe(
        (links) => {
          this.links = links;
        },
        (err) => {
          console.log("Se produjo un error:", err);
        });
  }

}
