import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LinkModel } from "src/app/models/link.model";
import { LinksService } from "src/app/service/links.service";
import Swal from "sweetalert2";
import {AuthService} from '../../../service/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';

export interface linksTable {
  name: string;
  position: string;
  actions: string;
}

@Component({
  selector: "app-GestionLinksDeInteres",
  templateUrl: "./GestionLinksDeInteres.component.html",
  styleUrls: ["./GestionLinksDeInteres.component.css"],
})
export class GestionLinksDeInteresComponent implements OnInit {
  link: LinkModel = {
    id: "",
    linkUrl: "",
    imageSrc: "",
  };
  links: any[];

  displayedColumns: string[] = ["imageSrc", "orden", "linkUrl", "actions"];
  dataSource = [];

  constructor(private router: Router,
              private authService: AuthService,
              private spinnerService: NgxSpinnerService,
              private linksService: LinksService) {}

  ngOnInit() {
    this.authService.verificaRol('superadmin').then((verificado: any) => {
      if (verificado) {
        this.cargarLinks();
      } else {
        this.router.navigateByUrl('/home');
      }
    });
  }

  cargarLinks() {
    this.spinnerService.show();
    this.linksService.getAllLinks().subscribe(
      (links) => {
        this.spinnerService.hide();
        this.dataSource = links;
      },
      (err) => {
        console.log("Se produjo un error:", err);
      }
    );
  }

  onEdit(link) {
    this.link.linkUrl = link.linkUrl;
    this.link.imageSrc = link.imageSrc;
    this.router.navigate(["createOrEditLink", link.id]);
  }

  onDelete(link) {
    this.link = link;
    Swal.fire({
      title: "¿Esta seguro que desea eliminar este link?",
      text: "El cambio es irreversible",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.value) {
        this.linksService
          .deleteLinkById(this.link)
          .then(() => this.router.navigate(["/gestionLinksDeInteres"]));
        Swal.fire("Eliminado", "El link ha sido eliminado.", "success");
      }
    });
  }
}
