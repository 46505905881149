import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators, FormArray } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AngularFireStorage } from "@angular/fire/storage";
import { AuthService } from "src/app/service/auth.service";
import { EquipoModel } from "src/app/models/equipo.model";
import { UsuarioModel } from "src/app/models/usuario.model";
import { EquipamientoService } from "src/app/service/equipamiento.service";
import { Observable } from "rxjs/internal/Observable";
import Swal from "sweetalert2";
import { finalize } from "rxjs/operators";
import {SearchCountryField, TooltipLabel, CountryISO } from "ngx-intl-tel-input";
import { GrupoModel } from "src/app/models/grupo.model";
import { GruposService } from "src/app/service/grupos.service";

@Component({
  selector: "app-cargar-equipo",
  templateUrl: "./cargar-equipo.component.html",
  styleUrls: ["./cargar-equipo.component.css"],
  providers: [EquipamientoService],
})
export class CargarEquipoComponent implements OnInit {
  dependencies: any[];
  grupo: GrupoModel = {
    convNombre1: "",
    convNombre2: "",
    convWeb1: "",
    convWeb2: "",
  };
  forma: FormGroup;
  equipo: EquipoModel = new EquipoModel();
  dep: string[];
  conicet: string[];
  // usuarioResponsable
  usuario: UsuarioModel = new UsuarioModel();
  nombreCompleto: string;
  // Carga de foto
  photo: string;
  cargafoto = false;
  imagenSubir: File;
  imagenTemp: string | ArrayBuffer;
  uploadPercent: Observable<number>;
  urlImage: Observable<string>;
  // Tipo de servicios
  servicios: string[] = [];
  serviciosOrd: string[] = [];
  // Validacion Teléfono

  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.Argentina,
    CountryISO.UnitedKingdom,
  ];

  // Param
  parametro = false;

  @ViewChild("imageUser", { static: false }) inputImageUser: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private storage: AngularFireStorage,
    private auth: AuthService,
    private grupoService: GruposService,
    private equipoService: EquipamientoService,
    private activatedRoute: ActivatedRoute
  ) {
    this.crearFormulario();
  }

  ngOnInit() {
    this.grupo = new GrupoModel();
    this.grupoService.getAllDependencies().subscribe(
      (dependencies) => {
        this.dependencies = dependencies;
      },
      (err) => {
        console.log("Se produjo un error:", err);
      }
    );

    // Incializar servicios
    this.servicios = this.getServicios();
    this.serviciosOrd = this.servicios.sort();

    // Traigo al usuario
    this.auth.isAuth().subscribe((user) => {
      if (user) {
        this.usuario = user;
        this.usuario.id = user.uid;
      }
    });

    this.activatedRoute.params.subscribe((params) => {
      if (params.id !== "") {
        this.parametro = true;
        this.equipoService.getEquipoFirebase(params["id"]).subscribe((data) => {
          this.equipo = data;
          this.equipo.photoUrl = data.photoUrl;
          this.equipo.dependencia = data.dependencia;

          this.equipo.id = params.id;

          if (this.equipo.photoUrl) {
            this.photo = this.equipo.photoUrl;
          }

          if (this.equipo) {
            if (this.equipo.telefono) {
              this.forma.controls["telefono"].setValue(
                this.equipo.telefono.nationalNumber
              );
            }
          }

          if (this.equipo.dependencia) {
            this.forma.controls.dependencia.setValue(this.equipo.dependencia);
          }
          if (this.equipo.servicios) {
            this.equipo.servicios.forEach((element) => {
              this.serviciosItemsDesplegable.push(this.fb.control(element));
            });
          }
        });
      }
    });
  }

  crearFormulario() {
    this.forma = this.fb.group(
      {
        equipamiento: ["", [Validators.required]],
        codigo: ["", [Validators.required]],
        marca: ["", [Validators.required]],
        modelo: ["", [Validators.required]],
        dependencia: [[], [Validators.required]],
        photoUrl: [""],
        servicios: this.fb.array([], [Validators.required]),
        descripcion: ["", [Validators.required, Validators.maxLength(400)]],
        email: [
          "",
          [
            Validators.required,
            Validators.pattern("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"),
          ],
        ],
        telefono: [undefined, [Validators.required]],
      },
      {}
    );
  }

  // Servicios (dinamico)

  get serviciosItems() {
    return this.forma.get("servicios") as FormArray;
  }
  newServicio(): FormGroup {
    return this.fb.group(
      {
        servi: ["", [Validators.maxLength(40)]],
      },
      {
        // validators: this.validadores.minSelectedCheckboxes()
      }
    );
  }

  addServicio() {
    console.log("agrego servicio");
    this.serviciosItems.push(this.newServicio());
  }

  removeServicio(servicioIndex: number) {
    console.log("remuevo servicio");
    this.serviciosItems.removeAt(servicioIndex);
  }

  // servicios DEPLEGABLE
  get serviciosItemsDesplegable() {
    return this.forma.get("servicios") as FormArray;
  }

  get validacionServiciosDesplegable() {
    return (
      this.forma.get("servicios").invalid && this.forma.get("servicios").touched
    );
  }

  seleccionServicioDesplegable(servicios: string[]) {
    this.clearFormArray(this.serviciosItemsDesplegable);
    servicios.forEach((element) => {
      this.serviciosItemsDesplegable.push(this.fb.control(element));
    });
  }

  // Validaciones

  get correoNoValido() {
    return this.forma.get("email").invalid && this.forma.get("email").touched;
  }

  get telefonoNoValido() {
    if (this.usuario.telefono) {
      return this.forma.get("telefono").invalid;
    } else {
      return (
        this.forma.get("telefono").invalid && this.forma.get("telefono").touched
      );
    }
  }

  get descripcionNoValido() {
    return (
      this.forma.get("descripcion").invalid &&
      this.forma.get("descripcion").touched
    );
  }

  get equipamientoNoValido() {
    if (this.equipo.equipamiento) {
      return this.forma.get("equipamiento").invalid;
    } else {
      return (
        this.forma.get("equipamiento").invalid &&
        this.forma.get("equipamiento").touched
      );
    }
  }

  get codigoNoValido() {
    if (this.equipo.codigo) {
      return this.forma.get("codigo").invalid;
    } else {
      return (
        this.forma.get("codigo").invalid && this.forma.get("codigo").touched
      );
    }
  }

  get marcaNoValido() {
    if (this.equipo.marca) {
      return this.forma.get("marca").invalid;
    } else {
      return this.forma.get("marca").invalid && this.forma.get("marca").touched;
    }
  }

  get modeloNoValido() {
    if (this.equipo.modelo) {
      return this.forma.get("modelo").invalid;
    } else {
      return (
        this.forma.get("modelo").invalid && this.forma.get("modelo").touched
      );
    }
  }

  get validacionDependencias() {
    return (
      this.forma.get("dependencia").invalid &&
      this.forma.get("dependencia").touched
    );
  }

  get dependenciasItems() {
    return this.forma.get("dependencia") as FormArray;
  }
  get serviNoValido() {
    this.equipo.servicios = [];
    if (this.equipo.servicios.length) {
      return this.forma.get("servicios").invalid;
    } else {
      if (this.serviciosItems.length >= 0) {
        // && this.serviciosItems.at(0).touched
        console.log(this.serviciosItems.invalid);
        return this.serviciosItems.invalid && this.serviciosItems.touched;
      }
    }
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  // Foto
  onUpload(archivo: File) {
    if (!archivo) {
      return;
    }
    if (archivo.type.indexOf("image") < 0) {
      Swal.fire(
        "Sólo imágenes",
        "El archivo seleccionado no es una imagen",
        "error"
      );
      return;
    }

    const reader = new FileReader();
    const urlImagenTemp = reader.readAsDataURL(archivo);

    reader.onloadend = () => (this.imagenTemp = reader.result);

    this.imagenSubir = archivo;
    const id = Math.random().toString(36).substring(2);
    const file = archivo;
    const filePath = `equipamientos/equipo_${id}`;
    const ref = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);
    this.uploadPercent = task.percentageChanges();

    task
      .snapshotChanges()
      .pipe(finalize(() => (this.urlImage = ref.getDownloadURL())))
      .subscribe();
    this.cargafoto = true;
  }

  actualizarImg() {
    this.photo = this.inputImageUser.nativeElement.value;

    this.cargafoto = false;
  }

  guardar() {
    if (this.cargafoto === true || !this.photo) {
      Swal.fire({
        icon: "error",
        title: "Foto no cargada",
        text: "Por favor actualice foto..",
      });
      return;
    }

    if (this.photo) {
      this.forma.value.photoUrl = this.photo;
    }

    this.forma.value.idResponsable = this.usuario.id;

    if (this.forma.invalid) {
      return Object.values(this.forma.controls).forEach((control) => {
        Swal.fire({
          icon: "error",
          title: "Formulario no válido",
          text: "Por favor corrobore los campos..",
        });
        control.markAsTouched();
      });
    } else {
      let equip: EquipoModel = new EquipoModel();
      equip = this.forma.value;

      if (this.parametro === false) {
        this.equipoService.addEquipo(equip);
      } else {
        equip.id = this.equipo.id;
        this.equipoService.updateEquipo(equip);
      }

      this.router.navigate(["/equipos"]);
    }
  }

  getServicios() {
    return ["Docencia", "Investigación", "Servicio"];
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.Argentina, CountryISO.Canada];
  }

  eliminar() {
    Swal.fire({
      title: "¿Esta seguro que desea eliminar el equipamiento?",
      text: "El cambio es irreversible",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, eliminar",
    }).then((result) => {
      if (result.value) {
        this.equipoService
          .deleteEquipById(this.equipo)
          .then(() => this.router.navigate(["/equipos"]));
        Swal.fire("Eliminado", "El equipamiento ha sido eliminado.", "success");
      }
    });
  }
}
