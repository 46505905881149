import { Injectable } from "@angular/core";
import {
  AngularFirestore
} from "@angular/fire/firestore";

export interface Convocatoria {
  respuesta: number;
  tipoProyecto: string;
}

@Injectable({
  providedIn: "root",
})
export class PerfilService {
  constructor(
    private afs: AngularFirestore
  ) {}

  getAllDependencies() {
    return this.afs.collection("dependencias").valueChanges();
  }

  getConvocatoria() {
    const convocatorias: Convocatoria[] = [
      { respuesta: 1, tipoProyecto: "SIIP Tipo1" },
      { respuesta: 1, tipoProyecto: "SIIP Tipo2" },
      { respuesta: 1, tipoProyecto: "SIIP Tipo3" },
      { respuesta: 1, tipoProyecto: "SIIP Tipo4" },
      { respuesta: 1, tipoProyecto: "Vinculación" },
      { respuesta: 1, tipoProyecto: "Extensión Territorio" },
      { respuesta: 1, tipoProyecto: "Extensión Mauricio López" },
      { respuesta: 1, tipoProyecto: "Conicet" },
      { respuesta: 1, tipoProyecto: "Agencia" },
    ];

    return convocatorias;
  }

  getConvocatorias() {
    return [
      "SIIP Tipo1",
      "SIIP Tipo2",
      "SIIP Tipo3",
      "SIIP Tipo4",
      "Vinculación",
      "Extensión Territorio",
      "Extensión Mauricio López",
      "Conicet",
      "Agencia",
    ];
  }

  getDisciplinas() {
    return [
      "Agronomía",
      "Antropología, sociología y ciencias políticas",
      "Arquitectura",
      "Artes",
      "Biología",
      "Ciencias de la tierra, el mar y la atmósfera",
      "Derecho y jurisprudencia",
      "Economía, administración y contabilidad",
      "Educación",
      "Filosofía",
      "Física",
      "Historia y geografía",
      "Ingeniería",
      "Literatura y lingüística",
      "Matemática",
      "Medicina, odontología y ciencias de la salud",
      "Psicología",
      "Química, bioquímica y farmacia",
      "Veterinaria",
    ];
  }
}
